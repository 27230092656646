import React, { Suspense, useContext, useState } from "react";
import Box from "@mui/material/Box";
import ViewContext from "./ViewContext";
import TreeContext from "./TreeContext";
import OccuAppBar from "./components/OccuAppBar";
import NetworkErrorAlert from "./components/NetworkErrorAlert";
import CollapsibleTreePanel from "./components/CollapsibleTreePanel";
import CollapsibleRightPanel from "./components/CollapsibleRightPanel";
import AnalyticsNavIcons from "./components/AnalyticsNavIcons";

type Props = { children: React.ReactNode };

interface State {
  error: boolean;
}

const Wrapper = ({ children }: { children: React.ReactNode[] }) => {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      {children}
    </Box>
  );
};

const EverythingButAppBar = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Box flexGrow={1} minHeight={0} display="flex">
      {children}
    </Box>
  );
};

const View: React.FC<Props> = ({ children }) => {
  const {
    isMobileSized,
    isLeftPanelRelevant,
    isRightPanelRelevant,
    isPortfolioToolRelevant,
    handleNavigationClick,
    spaceId,
  } = useContext(ViewContext);
  const { hasNoSpaces } = useContext(TreeContext);

  const [state, setState] = useState<State>({
    error: false,
  });

  const handleRightPanelError = () => {
    setState({ error: true });
  };

  const appBarProps = { isMobileSized, handleNavigationClick };

  if (state.error) {
    return (
      <Wrapper>
        <OccuAppBar {...appBarProps} />
        <EverythingButAppBar>
          <NetworkErrorAlert />
        </EverythingButAppBar>
      </Wrapper>
    );
  }

  if (isMobileSized) {
    return (
      <Wrapper>
        <OccuAppBar {...appBarProps} />
        <EverythingButAppBar>
          <Box
            display="flex"
            height="100%"
            flex="1"
            flexDirection="column"
            minWidth={0}
            pt={4}
            px={8}
          >
            <Suspense>{children}</Suspense>
          </Box>
        </EverythingButAppBar>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <OccuAppBar {...appBarProps} />
      <EverythingButAppBar>
        {isPortfolioToolRelevant && <AnalyticsNavIcons />}
        {isLeftPanelRelevant && !hasNoSpaces && (
          <CollapsibleTreePanel spaceId={spaceId as number} />
        )}
        <Box
          display="flex"
          height="100%"
          flex="18"
          flexDirection="column"
          minWidth={0}
          pt={4}
          px={8}
        >
          <Suspense>{children}</Suspense>
        </Box>
        {isRightPanelRelevant && (
          <CollapsibleRightPanel
            onError={handleRightPanelError}
            spaceId={spaceId as number}
          />
        )}
      </EverythingButAppBar>
    </Wrapper>
  );
};

export default View;
