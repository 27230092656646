import React from "react";
import { AnyVal, SpaceNodeByIdMap } from "../interfaces";
import { DateTime } from "luxon";
import { visitorsBarColors, dataGridHeaderStyles, greyThree } from "../theme";
import {
  formatMediumDate,
  formatMonthDayYear,
  daysBetweenDates,
  formatMonthDayYearWithWeekday,
  deriveComparisonDatesLabel,
} from "../dates";
import { formatToTwelveHour } from "../numbers";
import {
  GridColDef,
  GridColumnGroupingModel,
  GridColumnVisibilityModel,
  GridRowsProp,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { formatNumberWithComma } from "../util/format";
import { DateRangeListItem } from "./analytics-space-view";

const zoomingOptions: Highcharts.Options["chart"] = {
  zooming: {
    type: "x",
    resetButton: {
      theme: {
        strokeWidth: 0,
        style: {
          color: "black",
          width: 90,
          height: 36,
          texttr: "capitalize",
        },
        fill: "white",
        stroke: greyThree,
        r: 8,
        states: {
          hover: {
            fill: "white",
            stroke: "black",
            style: {
              color: "black",
            },
          },
        },
      },
    },
  },
};

export const initialHourlyVisitorsOptions: Highcharts.Options = {
  chart: {
    type: "column",
    height: 300,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    column: {
      opacity: 0.9,
      groupPadding: 0,
      pointPadding: 0,
    },
    // series: {
    //   turboThreshold: 10000, // TODO: this might be needed for large data sets
    // },
  },
  title: { text: undefined }, // hides the title
  exporting: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: undefined,
    },
  },
};

export const deriveColor = (
  oldChartItem: HourlyVisitorsChartSeriesMetaDataItem | undefined,
  chartMetaData: HourlyVisitorsChartSeriesMetaDataItem[]
) => {
  if (oldChartItem) {
    return oldChartItem.color;
  }
  for (let i = 0; i < visitorsBarColors.length; i++) {
    const color = visitorsBarColors[i];
    const colorAlreadyUsed = chartMetaData.find((item) => item.color === color);
    if (!colorAlreadyUsed) {
      return color;
    }
  }
  return visitorsBarColors[chartMetaData.length / 2];
};

export interface HourlyVisitorsChartSeriesMetaDataItem {
  name: string; // name of the space
  seriesName: string; // name of the series (total)
  color: string; // color of the series
  id: number; // id of the space
}

export const deriveHourlyVisitorsChartSpacesMetaData = (
  parsedData: AnyVal[],
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  chartMetaData: HourlyVisitorsChartSeriesMetaDataItem[]
) => {
  const hourlyVisitorsSeriesMetaData: HourlyVisitorsChartSeriesMetaDataItem[] =
    [];

  const hourData = parsedData[0];
  if (!hourData) {
    return { hourlyVisitorsSeriesMetaData: [], series: [] };
  }
  comparisonSpaceIds.forEach((id) => {
    const chartData = hourData[id];
    if (!chartData) {
      return {
        hourlyVisitorsSeriesMetaData: [],
        series: [],
      };
    }
    const node = nodes[Number(id)];
    const oldChartItem = chartMetaData.find((item) => item.id === node.id);
    const color = deriveColor(oldChartItem, hourlyVisitorsSeriesMetaData);
    hourlyVisitorsSeriesMetaData.push({
      name: node.name,
      seriesName: node.name + " Total",
      color,
      id: node.id,
    });
  });

  const deriveSeriesData = () => {
    const testSeries: AnyVal[] = [];
    const chartDataOne = hourData[comparisonSpaceIds[0]];
    const chartDataHours = chartDataOne.Hours;
    const chartDataHoursKeys = Object.keys(chartDataHours);

    chartDataHoursKeys.forEach((hourKey: string) => {
      comparisonSpaceIds.forEach((id, index) => {
        const chartdata = hourData[id];
        if (!chartdata) {
          return [];
        }
        const hourChartDataTotal = chartdata.Hours[hourKey].Total;
        const node = nodes[id];
        const currentMetaDataItem = hourlyVisitorsSeriesMetaData.find(
          (item) => item.id === node.id
        );
        const color = currentMetaDataItem
          ? currentMetaDataItem.color
          : visitorsBarColors[index];
        const spaceName = node.name;
        testSeries.push({
          name: `${spaceName} Hourly Visitors ${formatToTwelveHour(hourKey)}`,
          type: "column",
          color,
          showInLegend: false,
          data: hourChartDataTotal,
        });
      });
    });
    return testSeries;
  };

  const series = deriveSeriesData();

  return { hourlyVisitorsSeriesMetaData, series };
};

export const deriveHourlyVisitorsChartDatesMetaData = (
  parsedData: AnyVal[],
  nodes: SpaceNodeByIdMap,
  dateRange: AnyVal,
  comparisonDateRanges: AnyVal[],
  chartMetaData: HourlyVisitorsChartSeriesMetaDataItem[]
) => {
  const hourlyVisitorsSeriesMetaData: HourlyVisitorsChartSeriesMetaDataItem[] =
    [];

  const datesToCompare: DateRangeListItem[] = [
    { ...dateRange, indexOfDateRange: 0 },
    ...comparisonDateRanges,
  ];
  const hourData = parsedData[0];
  if (!hourData) {
    return { hourlyVisitorsSeriesMetaData: [], series: [] };
  }
  datesToCompare.forEach(({ startDate, indexOfDateRange, endDate }) => {
    const spaceData = parsedData[indexOfDateRange];
    if (!spaceData) {
      return {
        hourlyVisitorsSeriesMetaData: [],
        series: [],
      };
    }
    const comparisonLabel = deriveComparisonDatesLabel(startDate, endDate);
    const oldChartItem = chartMetaData.find(
      (item) => item.name === comparisonLabel
    );
    const node = nodes[Object.keys(spaceData)[0]];
    const color = deriveColor(oldChartItem, hourlyVisitorsSeriesMetaData);
    hourlyVisitorsSeriesMetaData.push({
      name: comparisonLabel,
      seriesName: comparisonLabel + " Total",
      color,
      id: node.id,
    });
  });

  const deriveSeriesData = () => {
    const testSeries: AnyVal[] = [];
    const node = nodes[Object.keys(hourData)[0]];
    const chartDataOne = hourData[node.id];
    const chartDataHours = chartDataOne.Hours;
    const chartDataHoursKeys = Object.keys(chartDataHours);

    chartDataHoursKeys.forEach((hourKey: string) => {
      datesToCompare.forEach(({ startDate, indexOfDateRange, endDate }) => {
        const chartData = parsedData[indexOfDateRange];
        if (!chartData) {
          return [];
        }
        const hourChartDataTotal = chartData[node.id].Hours[hourKey].Total;
        const comparisonLabel = `${formatMonthDayYear(
          startDate.toISOString()
        )} - ${formatMonthDayYear(endDate.toISOString())}`;
        const currentMetaDataItem = hourlyVisitorsSeriesMetaData.find(
          (item) => item.name === comparisonLabel
        );
        const color = currentMetaDataItem
          ? currentMetaDataItem.color
          : visitorsBarColors[indexOfDateRange];
        testSeries.push({
          name: `${comparisonLabel} Hourly Visitors ${formatToTwelveHour(
            hourKey
          )}`,
          type: "column",
          color,
          showInLegend: false,
          data: hourChartDataTotal,
        });
      });
    });
    return testSeries;
  };
  const series = deriveSeriesData();
  return { hourlyVisitorsSeriesMetaData, series };
};

export const deriveHourlyVisitorsChartOptions = (
  parsedData: AnyVal,
  isDateComparison: boolean,
  options: Highcharts.Options,
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  comparisonDateRanges: AnyVal[],
  chartMetaData: HourlyVisitorsChartSeriesMetaDataItem[],
  dateRange: AnyVal
) => {
  if (!parsedData) {
    return { newHourOptions: options, hourlyVisitorsSeriesMetaData: [] };
  }
  const { hourlyVisitorsSeriesMetaData, series } = isDateComparison
    ? deriveHourlyVisitorsChartDatesMetaData(
        parsedData,
        nodes,
        dateRange,
        comparisonDateRanges,
        chartMetaData
      )
    : deriveHourlyVisitorsChartSpacesMetaData(
        parsedData,
        nodes,
        comparisonSpaceIds,
        chartMetaData
      );

  const days = daysBetweenDates(dateRange.startDate, dateRange.endDate);

  const deriveCategories = () => {
    const start = dateRange.startDate;
    const end = dateRange.endDate;

    const dates = [];

    for (
      let currentDate = new Date(start);
      currentDate <= end;
      currentDate.setUTCDate(currentDate.getUTCDate() + 1)
    ) {
      if (isDateComparison) {
        const weekday = currentDate.toLocaleDateString("en-US", {
          weekday: "short",
        });
        dates.push(weekday);
      } else {
        const month = currentDate.getUTCMonth() + 1;
        const day = currentDate.getUTCDate();
        dates.push(`${month.toString()}/${day.toString()}`);
      }
    }

    return dates;
  };

  const categories = deriveCategories();

  // Hides zoom if less than 7 days
  const newZoomOptions =
    days > 7 ? zoomingOptions : ({ zooming: { type: "none" } } as AnyVal);

  const minRange = days > 7 ? { minRange: 1 } : {};

  const newHourOptions = {
    ...options,
    chart: {
      ...options.chart,
      ...newZoomOptions,
    },
    tooltip: {
      formatter: function () {
        const date = new Date((this as AnyVal).key);
        const formattedDate = formatMonthDayYearWithWeekday(date.toISOString());
        const visitors = (this as AnyVal).y;
        return `<b>${formattedDate}</b><br />${
          (this as AnyVal).series.name
        }: ${visitors}`;
      },
    },
    series,
    xAxis: {
      ...minRange, // Allos deeper zoom level
      categories,
    },
  };
  return { newHourOptions, hourlyVisitorsSeriesMetaData };
};
// =================================================================================================
// Table stuff
// =================================================================================================

const deriveDefaultColumnProps = (
  isMobileSized: boolean
): Partial<GridColDef> => {
  return {
    flex: 1,
    align: "left",
    headerAlign: "left",
    sortable: !isMobileSized, // Removes sort on mobile
    sortComparator: gridStringOrNumberComparator,
    renderCell: undefined,
  };
};

const sortHourly = (v1: string, v2: string) => {
  const hour1 = Number(v1);
  const hour2 = Number(v2);
  return hour1 - hour2;
};

const dateColFormatter = (params: AnyVal, isMobileSized: boolean) => {
  return isMobileSized
    ? formatMonthDayYear(params.value as string)
    : DateTime.fromISO(params.value as string).toLocaleString(
        DateTime.DATE_MED
      );
};

const deriveDefaultHourlyColumns = (isMobileSized: boolean): GridColDef[] => {
  return [
    {
      field: "col1",
      headerName: "Date",
      type: "date",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: (params) => dateColFormatter(params, isMobileSized),
      minWidth: 90,
    },
    {
      field: "col2",
      headerName: "Hour",
      type: "date",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: (params) => formatToTwelveHour(params.value),
      sortComparator: sortHourly,
      minWidth: 90,
    },
    {
      field: "total1",
      headerName: "Visitors",
      type: "number",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: ({ value }: { value: AnyVal }) => {
        if (value === null) {
          return "-";
        }
        return `${formatNumberWithComma(value)}`;
      },
      renderHeader(params) {
        return (
          <Tooltip title="The total visitors to this space for the hour indicated">
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.colDef.headerName}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];
};

export const deriveHourlyVisitorsTableSpacesComparisonData = (
  dateRange: AnyVal,
  availableDays: string[],
  timeRange: number[],
  comparisonDateRanges: AnyVal[],
  isDateComparison: boolean,
  comparisonSpaceIds: string[],
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  isMobileSized: boolean
): [
  rows: GridRowsProp,
  columns: GridColDef[],
  columnGroupingModel: GridColumnGroupingModel,
  columnVisibilityModel: GridColumnVisibilityModel
] => {
  if (!parsedData) {
    return [[], [], [], {}];
  }
  if (isDateComparison) {
    return deriveTableDatesComparisonData(
      comparisonSpaceIds[0],
      dateRange,
      availableDays,
      timeRange,
      comparisonDateRanges,
      parsedData,
      nodes,
      isMobileSized
    );
  }
  const hourlyVisitorsData = parsedData[0];
  if (!hourlyVisitorsData) {
    return [[], [], [], {}];
  }

  const deriveSpacesColumns = (): GridColDef[] => {
    if (comparisonSpaceIds.length === 1) {
      return deriveDefaultHourlyColumns(isMobileSized);
    }
    const columns: GridColDef[] = [];

    const valueFormatter = () => {
      return {
        valueFormatter: ({ value }: { value: AnyVal }) => {
          if (value === null) {
            return "-";
          }
          return `${formatNumberWithComma(value)}`;
        },
      };
    };
    // Initial columns
    columns.push({
      field: "col1",
      headerName: "Date",
      type: "date",
      valueFormatter: (params) => dateColFormatter(params, isMobileSized),
      ...deriveDefaultColumnProps(isMobileSized),
      renderHeader: undefined,
    });
    columns.push({
      field: "col2",
      headerName: "Hour",
      type: "date",
      valueFormatter: (params) => formatToTwelveHour(params.value),
      ...deriveDefaultColumnProps(isMobileSized),
      sortComparator: sortHourly,
      renderHeader: undefined,
    });
    // Gather and push all total columns
    comparisonSpaceIds.forEach((id: string, i: number) => {
      columns.push({
        field: `total${i + 1}`,
        headerName: nodes[id].name,
        type: "number",
        ...valueFormatter(),
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
      });
    });
    return columns;
  };

  const deriveColumnGroupingModel = (): GridColumnGroupingModel => {
    if (comparisonSpaceIds.length === 1) {
      return [];
    }
    const columnGroupingModel: GridColumnGroupingModel = [];
    const totalColumns: GridColDef[] = [];
    comparisonSpaceIds.forEach((id: string, i: number) => {
      totalColumns.push({ field: `total${i + 1}` });
    });
    columnGroupingModel.push({
      groupId: "Total Visitors",
      children: totalColumns,
      headerAlign: "center",
      renderHeaderGroup(params) {
        return (
          <Tooltip title="The total visitors to this space for the hour indicated">
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.headerName}
            </Typography>
          </Tooltip>
        );
      },
    });
    return columnGroupingModel;
  };

  const deriveRows = (): GridRowsProp => {
    const spaceDataOne = hourlyVisitorsData[comparisonSpaceIds[0]];
    if (!spaceDataOne) {
      return [];
    }
    const spaceDataOneHours = spaceDataOne.Hours;
    const spaceDataOneHourKeys = Object.keys(spaceDataOneHours);

    if (comparisonSpaceIds.length === 1) {
      const rows: AnyVal = [];
      // loop through each date
      spaceDataOneHours[spaceDataOneHourKeys[0]].Total.forEach(
        (item: AnyVal, i: number) => {
          spaceDataOneHourKeys.forEach((hourKey: string) => {
            const hourData = spaceDataOneHours[hourKey];
            const rowId = rows.length + 1;
            const total = hourData.Total[i][1];
            const hasDate = availableDays.includes(item[0]);
            const isHourWithinTimeRange =
              Number(hourKey) >= timeRange[0] &&
              Number(hourKey) <= timeRange[1];
            if (hasDate && isHourWithinTimeRange) {
              rows.push({
                id: rowId,
                col1: item[0], // date
                col2: hourKey, // hour
                total1: total, // total
              });
            }
          });
        }
      );
      return rows;
    } else {
      const rows: AnyVal = [];
      // loop through each date
      spaceDataOneHours[spaceDataOneHourKeys[0]].Total.forEach(
        (item: AnyVal, i: number) => {
          spaceDataOneHourKeys.forEach((hourKey: string) => {
            const hourData = spaceDataOneHours[hourKey];
            const rowId = rows.length + 1;
            const hasDate = availableDays.includes(item[0]);
            const isHourWithinTimeRange =
              Number(hourKey) >= timeRange[0] &&
              Number(hourKey) <= timeRange[1];
            const row: AnyVal = {
              id: rowId,
              col1: item[0], // date
              col2: hourKey, // hour
              total1: hourData.Total[i][1], // total
            };
            if (hasDate && isHourWithinTimeRange) {
              comparisonSpaceIds.forEach((id: string, index: number) => {
                const spaceHourData = hourlyVisitorsData[id].Hours;
                row[`total${index + 1}`] = spaceHourData[hourKey].Total[i][1];
              });
              rows.push(row);
            }
          });
        }
      );
      return rows;
    }
  };

  const columns = deriveSpacesColumns();
  const columnGroupingModel = deriveColumnGroupingModel();
  const rows = deriveRows();
  const columnVisibilityModel = {};
  return [rows, columns, columnGroupingModel, columnVisibilityModel];
};

const deriveDateTableToolTipAndValue = (parsedMetaData: AnyVal) => {
  const date = formatMediumDate(parsedMetaData.date as string);
  if (parsedMetaData.value === null) {
    return {
      tooltip: `${date} Total: -`,
      tableValue: "-",
    };
  }
  const valueString = formatNumberWithComma(parsedMetaData.value);
  return {
    tooltip: `${date} Total: ${valueString}`,
    tableValue: valueString,
  };
};

const deriveDateComparisonTableColumnProps = () => {
  return {
    sortComparator: (v1: string, v2: string) => {
      const parsedV1 = JSON.parse(v1);
      const parsedV2 = JSON.parse(v2);
      return parsedV1.value - parsedV2.value;
    },
    renderCell: (params: AnyVal) => {
      const parsedMetaData = JSON.parse(params.value as string);
      const { tooltip, tableValue } =
        deriveDateTableToolTipAndValue(parsedMetaData);
      return (
        <Tooltip title={tooltip}>
          <Typography
            sx={{
              fontSize: "inherit",
              color: "inherit",
              fontWeight: "inherit",
            }}
          >
            {tableValue}
          </Typography>
        </Tooltip>
      );
    },
  };
};

export const deriveTableDatesComparisonData = (
  spaceId: string,
  dateRange: AnyVal,
  availableDays: string[],
  timeRange: number[],
  comparisonDateRanges: AnyVal[],
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  isMobileSized: boolean
): [
  rows: GridRowsProp,
  columns: GridColDef[],
  columnGroupingModel: GridColumnGroupingModel,
  columnVisibilityModel: GridColumnVisibilityModel
] => {
  const datesToCompare: DateRangeListItem[] = [
    { ...dateRange, indexOfDateRange: 0 },
    ...comparisonDateRanges,
  ];
  const hourlyVisitorsData = parsedData;

  const deriveDatesColumns = (): GridColDef[] => {
    const columns: GridColDef[] = [];
    // Initial columns
    columns.push({
      field: "col1",
      headerName: "Date",
      type: "date",
      valueFormatter: (params) => dateColFormatter(params, isMobileSized),
      ...deriveDefaultColumnProps(isMobileSized),
      renderHeader: undefined,
    });
    columns.push({
      field: "col2",
      headerName: "Hour",
      type: "date",
      valueFormatter: (params) => formatToTwelveHour(params.value),
      ...deriveDefaultColumnProps(isMobileSized),
      sortComparator: sortHourly,
      renderHeader: undefined,
    });
    // Gather and push all total columns
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      columns.push({
        field: `total${i + 1}`,
        headerName: deriveComparisonDatesLabel(
          dateRange.startDate,
          dateRange.endDate
        ),
        type: "number",
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
        valueFormatter: undefined,
        ...deriveDateComparisonTableColumnProps(),
      });
    });
    return columns;
  };

  const deriveColumnGroupingModel = (): GridColumnGroupingModel => {
    const columnGroupingModel: GridColumnGroupingModel = [];
    const totalColumns: GridColDef[] = [];
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      totalColumns.push({ field: `total${i + 1}` });
    });
    columnGroupingModel.push({
      groupId: "Total Visitors",
      children: totalColumns,
      headerAlign: "center",
      renderHeaderGroup(params) {
        return (
          <Tooltip title="The total visitors to this space for the hour indicated">
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.headerName}
            </Typography>
          </Tooltip>
        );
      },
    });
    return columnGroupingModel;
  };

  const deriveRows = (): GridRowsProp => {
    const spaceDataOne = hourlyVisitorsData[0][spaceId];
    if (!spaceDataOne) {
      return [];
    }
    const spaceDataOneHours = spaceDataOne.Hours;
    const spaceDataOneHourKeys = Object.keys(spaceDataOneHours);
    const rows: AnyVal = [];
    // loop through each date
    spaceDataOneHours[spaceDataOneHourKeys[0]].Total.forEach(
      (item: AnyVal, i: number) => {
        spaceDataOneHourKeys.forEach((hourKey: string) => {
          const hourData = spaceDataOneHours[hourKey];
          const rowId = rows.length + 1;
          const hasDate = availableDays.includes(item[0]);
          const isHourWithinTimeRange =
            Number(hourKey) >= timeRange[0] && Number(hourKey) <= timeRange[1];
          const row: AnyVal = {
            id: rowId,
            col1: item[0], // date
            col2: hourKey, // hour
            total1: hourData.Total[i][1], // total
          };
          if (hasDate && isHourWithinTimeRange) {
            datesToCompare.forEach(({ indexOfDateRange }, index) => {
              const spaceHourData =
                hourlyVisitorsData[indexOfDateRange][spaceId].Hours;
              row[`total${index + 1}`] = JSON.stringify({
                date: spaceHourData[hourKey].Total[i][0],
                value: spaceHourData[hourKey].Total[i][1],
              });
            });
            rows.push(row);
          }
        });
      }
    );
    return rows;
  };

  const columns = deriveDatesColumns();
  const columnGroupingModel = deriveColumnGroupingModel();
  const rows = deriveRows();
  const columnVisibilityModel = {};
  return [rows, columns, columnGroupingModel, columnVisibilityModel];
};
