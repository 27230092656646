import React, { useContext, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  AppRoutes,
  PageTitles,
  getCurrentFirstPath,
  getCurrentSpacesTabRoute,
  spacesRoutes,
  nonSpacesRoutes,
  deriveSpaceId,
  getCurrentAnalyticsTabRoute,
  AnalyticsRoute,
  SpaceRoutes,
} from "./routing";
import ViewContext from "./ViewContext";

import { PageChangeHandler, ReactChangeEventHandler } from "./interfaces";
import {
  defaultFilters as defaultUserManagementFilters,
  Filters as UserManagementFilters,
  UserRoleMenuItem,
  UserStatusMenuItem,
} from "./views/UserManagement/interfaces";
import { deriveSelectedDropdownValues } from "./util";
import TreeContext from "./TreeContext";
import { SpaceType } from "common/trees";

interface Props {
  children: React.ReactNode;
}

interface ViewState {
  previousPathname: string | null;
  isLeftPanelOpen: boolean;
  isLeftPanelRelevant: boolean;
  isRightPanelOpen: boolean;
  isRightPanelRelevant: boolean;
  isPortfolioToolOpen: boolean;
  isPortfolioToolRelevant: boolean;
  userManagement: {
    filters: UserManagementFilters;
  };
}

const deriveInitialState = (
  pathname: string,
  isMobileSized: boolean
): ViewState => {
  const firstPath = getCurrentFirstPath(pathname);

  const initial: ViewState = {
    previousPathname: null,
    isLeftPanelOpen: true,
    isLeftPanelRelevant: true,
    isRightPanelOpen: false,
    isRightPanelRelevant: true,
    isPortfolioToolRelevant: true,
    isPortfolioToolOpen: false,
    userManagement: {
      filters: { ...defaultUserManagementFilters },
    },
  };

  if (isMobileSized) {
    initial.isRightPanelRelevant = false;
    initial.isLeftPanelOpen = false;
    initial.isPortfolioToolRelevant = false;
    initial.isPortfolioToolOpen = false;
  }

  if (nonSpacesRoutes.has(firstPath as AppRoutes)) {
    initial.isLeftPanelRelevant = false;
    initial.isRightPanelRelevant = false;
  }

  return initial;
};

const ViewProvider: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const { nodes, hasNoSpaces } = useContext(TreeContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileSized = useMediaQuery(theme.breakpoints.down("sm"));

  const firstPath = getCurrentFirstPath(pathname);
  const splitPathname = pathname.split("/");
  const isPortfolio = splitPathname.includes(AppRoutes.Portfolio);
  const spaceId = deriveSpaceId(firstPath, splitPathname);

  const [state, setState] = useState<ViewState>(
    deriveInitialState(pathname, isMobileSized)
  );
  const {
    previousPathname,
    isLeftPanelOpen,
    isLeftPanelRelevant,
    isRightPanelOpen,
    isRightPanelRelevant,
    isPortfolioToolRelevant,
    isPortfolioToolOpen,
    userManagement: { filters },
  } = state;

  if (pathname !== previousPathname) {
    const stateUpdates: Partial<ViewState> = {};

    // if the path changes, and we're on mobile and the left panel is open
    // we want to hide the panel because it overlays the mobile viewport
    // so that the user can see the new view
    if (isMobileSized && isLeftPanelOpen) {
      stateUpdates.isLeftPanelOpen = false;
    }

    // only show rightPanel on spaceRoutes with a valid spaceId
    if (!spaceId) {
      stateUpdates.isRightPanelRelevant = false;
    }

    // if the user is on a space route, the left panel is relevant
    if (spacesRoutes.has(firstPath as AppRoutes) && !isPortfolio) {
      stateUpdates.isLeftPanelRelevant = true;
    } else {
      stateUpdates.isLeftPanelRelevant = false;
      stateUpdates.isRightPanelRelevant = false;
    }

    if (firstPath === AppRoutes.Analytics || isPortfolio) {
      stateUpdates.isPortfolioToolRelevant = true;
      if (isPortfolio) {
        stateUpdates.isPortfolioToolOpen = true;
      }
    } else {
      stateUpdates.isPortfolioToolRelevant = false;
      stateUpdates.isPortfolioToolOpen = false;
    }

    // if the non-mobile screen user is on a space route with spaceId, the right panel is relevant
    if (spacesRoutes.has(firstPath as AppRoutes) && spaceId && !isMobileSized) {
      stateUpdates.isRightPanelRelevant = true;
    }

    if ((firstPath === AppRoutes.Analytics || isPortfolio) && !isMobileSized) {
      stateUpdates.isPortfolioToolRelevant = true;
      if (isPortfolio) {
        stateUpdates.isPortfolioToolOpen = true;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...stateUpdates,
      previousPathname: pathname,
    }));
  }

  let pageTitle = "";
  if (isPortfolio) {
    pageTitle = PageTitles.Portfolio;
  } else if (firstPath === AppRoutes.Analytics || pathname === "/") {
    pageTitle = PageTitles.Analytics;
  } else if (firstPath === AppRoutes.LiveData) {
    pageTitle = PageTitles.LiveData;
  } else if (firstPath === AppRoutes.Spaces) {
    pageTitle = PageTitles.Spaces;
  } else if (
    firstPath === AppRoutes.UserManagement ||
    firstPath === AppRoutes.Users
  ) {
    pageTitle = PageTitles.UserManagement;
  } else if (firstPath === AppRoutes.MyAccount) {
    pageTitle = PageTitles.MyAccount;
  }

  document.title = `Occuspace`;

  if (pageTitle) {
    document.title = `Occuspace | ${pageTitle}`;
  }

  useEffect(() => {
    if (isMobileSized) {
      setState((prevState) => ({
        ...prevState,
        isLeftPanelOpen: false,
        isRightPanelOpen: false,
        isRightPanelRelevant: false,
        isLeftPanelRelevant: false,
        isPortfolioToolOpen: false,
      }));
    } else {
      const isRightPanelRelevant =
        spacesRoutes.has(firstPath as AppRoutes) && Boolean(spaceId);
      const isPortfolioToolRelevant =
        firstPath === AppRoutes.Analytics || isPortfolio;
      const isPortfolioToolOpen = isPortfolio;
      setState((prevState) => ({
        ...prevState,
        isLeftPanelOpen: true,
        isRightPanelRelevant,
        isPortfolioToolRelevant,
        isPortfolioToolOpen,
      }));
    }
  }, [isMobileSized]);

  const handlePortfolioToolToggle = (
    spaceId: number,
    route: AppRoutes,
    paramsString: string
  ) => {
    const isPortfolioToolOpen = route === AppRoutes.Portfolio;
    setState((prevState) => ({
      ...prevState,
      isPortfolioToolOpen,
    }));
    if (isPortfolioToolOpen) {
      navigate(
        `/${AppRoutes.Analytics}/${AppRoutes.Portfolio}/${spaceId}?${paramsString}`
      );
    } else {
      navigate(
        `/${route}/${spaceId}/${AnalyticsRoute.Overview}/?${paramsString}`
      );
    }
  };

  const handleLeftPanelToggle = () => {
    setState((prevState) => ({
      ...prevState,
      isLeftPanelOpen: !prevState.isLeftPanelOpen,
    }));
  };

  const handleRightPanelToggle = () => {
    setState((prevState) => ({
      ...prevState,
      isRightPanelOpen: !prevState.isRightPanelOpen,
    }));
  };

  // put this here so the navigation bars can share this logic
  const handleNavigationClick = (route: AppRoutes) => {
    const navSplitPathname = pathname.split("/");
    const navFirstPath = getCurrentFirstPath(pathname);
    const spaceId = deriveSpaceId(route, navSplitPathname);
    const currentSpacetab = getCurrentSpacesTabRoute(navSplitPathname);
    const currentAnalyticsTab = getCurrentAnalyticsTabRoute(navSplitPathname);
    const currentlySelectedTab =
      typeof currentSpacetab === typeof SpaceRoutes
        ? currentSpacetab
        : SpaceRoutes.Overview;
    const currentAnalyticsTabRoute =
      typeof currentAnalyticsTab === typeof AnalyticsRoute
        ? currentAnalyticsTab
        : AnalyticsRoute.Overview;

    if (!spaceId) {
      if (route === AppRoutes.Spaces) {
        if (hasNoSpaces) {
          navigate(`/${AppRoutes.Spaces}/${SpaceRoutes.WiFiCreds}`);
          return;
        }
        navigate(`/${AppRoutes.Spaces}/${currentlySelectedTab}`);
        return;
      }
      if (route === AppRoutes.Analytics) {
        navigate(`/${AppRoutes.Analytics}`);
        return;
      }
      navigate(`/${route}`);
      return;
    }
    if (navFirstPath === route && !spaceId) {
      if (route === AppRoutes.Spaces) {
        navigate(`/${AppRoutes.Spaces}/${currentlySelectedTab}`);
        return;
      }
      navigate(`/${route}`);
      return;
    }
    const currentNode = nodes[spaceId];
    if (!currentNode) {
      navigate(`/${route}`);
      return;
    }
    const { type, occupancy, visitorship } = currentNode;
    const isEmptyZone = type === SpaceType.Zone && !occupancy && !visitorship;
    if (route === AppRoutes.Spaces) {
      if (navFirstPath === route) {
        return;
      }
      navigate(`/${route}/${spaceId}/${currentlySelectedTab}`);
      return;
    }
    if (route === AppRoutes.Analytics) {
      if (isEmptyZone) {
        navigate(
          `/${route}/${currentNode.parentId}/${currentAnalyticsTabRoute}`
        );
        return;
      }
      if (navFirstPath === route) {
        return;
      }
      navigate(
        `/${AppRoutes.Analytics}/${spaceId}/${currentAnalyticsTabRoute}`
      );
      return;
    }
    if (route === AppRoutes.LiveData) {
      if (isEmptyZone) {
        navigate(`/${route}/${currentNode.parentId}`);
        return;
      }
      if (navFirstPath === route) {
        return;
      }
      navigate(`/${route}/${spaceId}`);
      return;
    }
    navigate(`/${route}`);
    return;
  };

  const handleStatusFilterChange =
    (userStatuses: UserStatusMenuItem[]) =>
    ({ target: { value } }: SelectChangeEvent<string[]>) => {
      const newStatuses = deriveSelectedDropdownValues(
        value as string[],
        userStatuses,
        filters.selectedStatuses
      );
      setState((prevState) => ({
        ...prevState,
        userManagement: {
          filters: {
            ...prevState.userManagement.filters,
            currentPage: 1,
            selectedStatuses: newStatuses as string[],
          },
        },
      }));
    };

  const handleRoleFilterChange =
    (userRoles: UserRoleMenuItem[]) =>
    ({ target: { value } }: SelectChangeEvent<string[]>) => {
      const newRoles = deriveSelectedDropdownValues(
        value as string[],
        userRoles,
        filters.selectedRoles
      );
      setState((prevState) => ({
        ...prevState,
        userManagement: {
          filters: {
            ...prevState.userManagement.filters,
            currentPage: 1,
            selectedRoles: newRoles as string[],
          },
        },
      }));
    };

  const handleSearchChange: ReactChangeEventHandler = ({
    target: { value },
  }) => {
    setState((prevState) => ({
      ...prevState,
      userManagement: {
        filters: {
          ...prevState.userManagement.filters,
          currentPage: 1,
          search: value,
        },
      },
    }));
  };

  const handlePageChange: PageChangeHandler = (_, value) => {
    setState((prevState) => ({
      ...prevState,
      userManagement: {
        filters: {
          ...prevState.userManagement.filters,
          currentPage: value,
        },
      },
    }));
  };

  const viewContextValue = {
    isMobileSized,
    isLeftPanelOpen,
    isLeftPanelRelevant,
    isRightPanelOpen,
    isRightPanelRelevant,
    isPortfolioToolOpen,
    isPortfolioToolRelevant,
    handlePortfolioToolToggle,
    handleLeftPanelToggle,
    handleRightPanelToggle,
    handleNavigationClick,
    userManagement: {
      filters,
      handleStatusFilterChange,
      handleRoleFilterChange,
      handleSearchChange,
      handlePageChange,
    },
    spaceId: deriveSpaceId(firstPath, splitPathname),
  };

  return (
    <ViewContext.Provider value={viewContextValue}>
      {children}
    </ViewContext.Provider>
  );
};

export default ViewProvider;
