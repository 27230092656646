import { DateTime } from "luxon";
import { DateRange } from "./state/analytics";
import {
  ChartActions,
  ChartType,
  DailyDwelltimeChartActions,
  DailyVisitorsChartActions,
  DwelltimeTabState,
  HourlyVisitorsChartActions,
  OccupancyTabState,
  VisitorsTabState,
} from "./state/analytics-space-view";

export function getCurrentFirstPath(pathname: string): string {
  const splitPathname = pathname.split("/");
  const firstPath = splitPathname.length > 0 ? splitPathname[1] : "";

  return firstPath;
}

export function deriveSpaceIdFromPath(pathname: string): number | null {
  const splitPathname = pathname.split("/");
  const spaceIdString = splitPathname.length >= 2 ? splitPathname[2] : null;
  const spaceId = Number.parseInt(spaceIdString as string);
  return spaceId ? spaceId : null;
}

export function getCurrentSpacesTabRoute(splitPathname: string[]): SpaceRoutes {
  // Navigation between spaces
  if (splitPathname.length === 4) {
    return splitPathname[3] as SpaceRoutes;
  }
  // Navigation from root to a space
  if (splitPathname[1] === AppRoutes.Spaces && splitPathname.length === 3) {
    return splitPathname[2] as SpaceRoutes;
  }
  // Navigation from approute outside of spaces
  return SpaceRoutes.Overview;
}

export function getCurrentAnalyticsTabRoute(
  splitPathname: string[]
): AnalyticsRoute {
  // Navigation between spaces
  if (splitPathname.length >= 4) {
    return splitPathname[3] as AnalyticsRoute;
  }
  // Navigation from approute outside of spaces
  return AnalyticsRoute.Overview;
}

export function isCurrentAnalyticsTabRouteAvailable(
  currentTab: AnalyticsRoute,
  hasVisitors: boolean,
  hasDwellTime: boolean,
  hasOccupancy: boolean
): boolean {
  if (currentTab === AnalyticsRoute.Visitors) {
    return hasVisitors;
  }
  if (currentTab === AnalyticsRoute.Dwelltime) {
    return hasDwellTime;
  }
  if (currentTab === AnalyticsRoute.Occupancy) {
    return hasOccupancy;
  }
  return true;
}

export enum AppRoutes {
  Analytics = "analytics",
  Login = "login",
  LiveData = "live-data",
  Spaces = "spaces",
  UserManagement = "user-management",
  Users = "users",
  MyAccount = "my-account",
  Register = "register",
  ResetPassword = "reset-password",
  Portfolio = "portfolio",
}

export enum SpaceRoutes {
  Overview = "overview",
  Install = "install",
  Headcount = "head-count",
  Hours = "hours",
  WiFiCreds = "wifi",
  Signage = "digital-signage",
}

export enum AnalyticsRoute {
  Overview = "overview",
  Occupancy = "occupancy",
  Visitors = "visitors",
  Dwelltime = "dwell-time",
}

export interface Params {
  spaceId?: string;
  selectedTab?: string;
  firstParam?: string;
}

export enum PageTitles {
  Analytics = "Space Analytics",
  Portfolio = "Portfolio Analytics",
  LiveData = "Live Data",
  Spaces = "Spaces",
  UserManagement = "User Management",
  MyAccount = "My Account",
}

export const spacesRoutes = new Set([
  AppRoutes.Spaces,
  AppRoutes.Analytics,
  AppRoutes.LiveData,
]);

export const nonSpacesRoutes = new Set([
  AppRoutes.UserManagement,
  AppRoutes.Users,
  AppRoutes.MyAccount,
]);

export const deriveSpaceId = (
  firstPath: string,
  splitPathname: string[]
): number | null => {
  // Handle case where the path does not match the expected format
  if (
    !spacesRoutes.has(firstPath as AppRoutes) &&
    firstPath !== AppRoutes.Portfolio
  ) {
    return null;
  }

  // The spaceId is expected to be at index 2 if it's `/analytics/:spaceId`
  if (splitPathname.length < 3) {
    return null;
  }

  // Extract the spaceId, accounting for `/portfolio` in the URL
  const potentialSpaceId = splitPathname[2];

  // If the third segment is "portfolio", the spaceId should be in index 3
  if (potentialSpaceId === AppRoutes.Portfolio && splitPathname.length > 3) {
    const spaceId = Number.parseInt(splitPathname[3]);

    if (Number.isNaN(spaceId)) {
      return null;
    }

    return spaceId;
  }

  // For other cases, like `/analytics/:spaceId`, the spaceId is in index 2
  const spaceId = Number.parseInt(potentialSpaceId);

  if (Number.isNaN(spaceId)) {
    return null;
  }

  return spaceId;
};

export const updateAnalyticsSearchParams = (
  params: URLSearchParams,
  currentTab: AnalyticsRoute,
  tabs: {
    occupancy: OccupancyTabState;
    visitors: VisitorsTabState;
    dwelltime: DwelltimeTabState;
  },
  dailyOccupancyChart: ChartActions,
  hourlyOccupancyChart: ChartActions,
  byWeekdayAndHourChart: {
    options: AnyVal;
  },
  dailyVisitors: DailyVisitorsChartActions,
  hourlyVisitors: HourlyVisitorsChartActions,
  dailyDwelltime: DailyDwelltimeChartActions,
  omitZero: boolean,
  dateRange: DateRange,
  timeRange: number[],
  currentComparisonSpaces: string | null,
  currentComparisonDates: string | null,
  daysOfWeek: string
) => {
  const { occupancy, visitors } = tabs;
  params.set("startDate", DateTime.fromJSDate(dateRange.startDate).toISODate());
  params.set("endDate", DateTime.fromJSDate(dateRange.endDate).toISODate());
  params.set("daysOfWeek", daysOfWeek);
  params.set("startHour", timeRange[0].toString());
  params.set("endHour", timeRange[1].toString());
  params.set("excludeEmptyHours", omitZero ? "true" : "false");

  if (currentTab === AnalyticsRoute.Overview) {
    if (params.has("chartType")) {
      params.delete("chartType");
    }
    if (params.has("showPeak")) {
      params.delete("showPeak");
    }
  }

  if (currentTab === AnalyticsRoute.Occupancy) {
    params.set("chartType", occupancy.chartType);
    params.set("comparisonSpaces", currentComparisonSpaces || "");
    params.set("comparisonDates", currentComparisonDates || "");
    if (occupancy.chartType === ChartType.Daily) {
      params.set(
        "showPeak",
        dailyOccupancyChart.shouldDisplayPeak ? "true" : "false"
      );
    }
    if (occupancy.chartType === ChartType.Hourly) {
      params.set(
        "showPeak",
        hourlyOccupancyChart.shouldDisplayPeak ? "true" : "false"
      );
    }
    if (occupancy.chartType === ChartType.WeekdayAndHour) {
      if (params.has("showPeak")) {
        params.delete("showPeak");
      }
    }
  }

  if (currentTab === AnalyticsRoute.Visitors) {
    params.set("chartType", visitors.chartType);
    if (params.has("showPeak")) {
      params.delete("showPeak");
    }
    params.set("comparisonSpaces", currentComparisonSpaces || "");
    params.set("comparisonDates", currentComparisonDates || "");
  }

  if (
    currentTab === AnalyticsRoute.Visitors ||
    currentTab === AnalyticsRoute.Dwelltime
  ) {
    if (params.has("excludeEmptyHours")) {
      params.delete("excludeEmptyHours");
    }
    if (params.has("startHour")) {
      params.delete("startHour");
    }
    if (params.has("endHour")) {
      params.delete("endHour");
    }
  }

  if (currentTab === AnalyticsRoute.Dwelltime) {
    if (params.has("chartType")) {
      params.delete("chartType");
    }
    params.set("showPeak", dailyDwelltime.shouldDisplayPeak ? "true" : "false");
    params.set("comparisonSpaces", currentComparisonSpaces || "");
    params.set("comparisonDates", currentComparisonDates || "");
  }

  return params;
};

export const haveOverlappingParamsChanged = (
  oldParams: URLSearchParams,
  newParams: URLSearchParams
): boolean => {
  // Iterate through each parameter in the newParams and check if the value differs in oldParams
  for (const [key, newValue] of newParams.entries()) {
    const oldValue = oldParams.get(key);
    if (oldValue !== newValue) {
      return true; // A difference was found
    }
  }
  return false; // No differences found
};
