import Highcharts from "highcharts";
import {
  GridValidRowModel,
  GridColDef,
  GridColumnGroupingModel,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import {
  SpaceNode,
  SpaceNodeByIdMap,
  AnyVal,
  SpaceStatus,
} from "../interfaces";
import {
  DateRange,
  defaultFilters,
  FiltersState,
  getTimeRangeChangeState,
} from "./analytics";
import {
  ChartSeriesMetaDataItem,
  deriveDefaultYAxisOptions,
  initialDateOptions,
} from "./datechart";
import {
  DailyVisitorsChartSeriesMetaDataItem,
  deriveDailyVisitorsChartOptions,
  deriveDailyVisitorsTableSpacesComparisonData,
  initialDailyVisitorsOptions,
} from "./visitorship-chart";
import {
  HourlyVisitorsChartSeriesMetaDataItem,
  deriveHourlyVisitorsTableSpacesComparisonData,
  initialHourlyVisitorsOptions,
} from "./hourly-visitors-chart";
import {
  analyticsFilterFormat,
  deriveComparisonDatesLabel,
  isZeroDate,
} from "../dates";
import {
  deriveHeatmapSeries,
  deriveHeatmapTableData,
  deriveHeatmapTooltip,
  deriveInitialHeatmapOptions,
} from "./heatmap";
import { deriveChartYAxisMax } from "./charts-common";
import { deriveHourChartOptions } from "./hour-chart";
import {
  deriveDateChartOptions,
  deriveTableSpacesComparisonData,
  deriveTableVisibilityModel,
} from "./date-chart";
import { AnalyticsRoute } from "../routing";
import { deriveHourlyVisitorsChartOptions } from "./hourly-visitors-chart";
import {
  AnalyticsChildSpace,
  GetDwelltimeSpaceAnalyticsOutput,
  GetOccupancySpaceAnalyticsOutput,
  GetVisitorshipSpaceAnalyticsOutput,
} from "../interfaces/analytics";
import {
  DailyDwelltimeSeriesMetaDataItem,
  deriveDailyDwellChartOptions,
  deriveDailyDwellChartSpacestooltip,
  deriveDailyDwellTableSpacesComparisonData,
  initialDailyDwelltimeOptions,
} from "./dwell-time-chart";
import { DateTime } from "luxon";

export enum ChartType {
  Daily = "Daily",
  Hourly = "Hourly",
  WeekdayAndHour = "Weekday & Hour",
}

export const occupancyChartTypeMenuItems: ChartTypeMenuItem[] = [
  { value: ChartType.Daily, name: "Daily" },
  { value: ChartType.Hourly, name: "Hourly" },
  { value: ChartType.WeekdayAndHour, name: "Weekday & Hour" },
];

export const visitorshipChartTypeMenuItems: ChartTypeMenuItem[] = [
  { value: ChartType.Daily, name: "Daily" },
  { value: ChartType.Hourly, name: "Hourly" },
];

export interface ChartTypeMenuItem {
  name: string;
  value: ChartType;
}

export interface AddComparisonDialogState {
  isOpen: boolean;
  isLoading: boolean;
  errorMessage: string;
  comparisonType: ComparisonType;
  comparisonSpaceIds: string[];
  comparisonDateRanges: DateRangeListItem[];
  initialComparisonSpaceIds: string[];
  initialComparisonDateRanges: DateRangeListItem[];
  otherChartComparisonSpaceIds: string[];
  otherChartComparisonDateRanges: DateRangeListItem[];
}

interface BasicChart {
  options: Highcharts.Options;
  data: AnyVal;
}

export interface OccupancyTabState {
  chartType: ChartType;
  daily: {
    columns: readonly GridColDef<AnyVal>[];
    rows: readonly GridValidRowModel[] | GridColDef[];
    groupingModel: GridColumnGroupingModel | undefined;
    columnVisibilityModel: GridColumnVisibilityModel;
    tableShouldShowPeak: boolean;
  };
  hourly: {
    columns: readonly GridColDef<AnyVal>[];
    rows: readonly GridValidRowModel[] | GridColDef[];
    groupingModel: GridColumnGroupingModel | undefined;
    columnVisibilityModel: GridColumnVisibilityModel;
    tableShouldShowPeak: boolean;
  };
  weekdayAndHour: {
    columns: readonly GridColDef<AnyVal>[];
    rows: readonly GridValidRowModel[] | GridColDef[];
  };
}

export interface VisitorsTabState {
  chartType: ChartType;
  daily: {
    columns: readonly GridColDef<AnyVal>[];
    rows: readonly GridValidRowModel[] | GridColDef[];
    groupingModel: GridColumnGroupingModel | undefined;
  };
  hourly: {
    columns: readonly GridColDef<AnyVal>[];
    rows: readonly GridValidRowModel[] | GridColDef[];
    groupingModel: GridColumnGroupingModel | undefined;
  };
}

export interface DwelltimeTabState {
  daily: {
    columns: readonly GridColDef<AnyVal>[];
    rows: readonly GridValidRowModel[] | GridColDef[];
    groupingModel: GridColumnGroupingModel | undefined;
    columnVisibilityModel: GridColumnVisibilityModel;
    tableShouldShowPeak: boolean;
  };
}

export interface State {
  currentTab: AnalyticsRoute;
  nodes: SpaceNodeByIdMap;
  spaceId: number | null;
  pageLoad: {
    occupancyData: GetOccupancySpaceAnalyticsOutput | null;
    visitorsData: GetVisitorshipSpaceAnalyticsOutput | null;
    dwelltimeData: GetDwelltimeSpaceAnalyticsOutput | null;
    childSpaces: AnalyticsChildSpace[];
    isLoading: boolean;
    error: boolean;
    showEarlyPreviewStartDate: string | null;
  };
  basicCharts: {
    byDate: BasicChart;
    byHour: BasicChart;
    byWeekdayAndHour: BasicChart;
    visitors: BasicChart;
    hourlyVisitors: BasicChart;
    dailyDwelltime: BasicChart;
  };
  tabs: {
    occupancy: OccupancyTabState;
    visitors: VisitorsTabState;
    dwelltime: DwelltimeTabState;
  };
  previousSpaceId: number | null;
  previousFilters: FiltersState;
  filters: FiltersState;
  currentPage: number;
  dialogs: {
    addComparison: AddComparisonDialogState;
    exportDataDialog: {
      isOpen: boolean;
      isLoading: boolean;
      errorMessage: string;
    };
  };
  selectedComparisonSpaceNode: SpaceNode | undefined;
  dateTimeComparisonRange: AnyVal;
  dailyOccupancyChart: ChartActions;
  hourlyOccupancyChart: ChartActions;
  byWeekdayAndHourChart: {
    options: AnyVal;
  };
  dailyVisitors: DailyVisitorsChartActions;
  hourlyVisitors: HourlyVisitorsChartActions;
  dailyDwelltime: DailyDwelltimeChartActions;
}

export interface DailyDwelltimeChartActions {
  shouldDisplayPeak: boolean;
  shouldDisplayAverage: boolean;
  options: Highcharts.Options;
  comparisonSpaceIds: string[];
  comparisonDateRanges: DateRangeListItem[];
  chartMetaData: DailyDwelltimeSeriesMetaDataItem[];
  busiestTotal: number | null;
  toggleSeriesView: boolean;
}

export interface ChartActions {
  shouldDisplayPeak: boolean;
  shouldDisplayAverage: boolean;
  isYAxisZoomed: boolean;
  options: Highcharts.Options;
  comparisonSpaceIds: string[];
  comparisonDateRanges: DateRangeListItem[];
  chartMetaData: ChartSeriesMetaDataItem[];
  busiestTotal: number | null;
  isYAxisPercentage: boolean;
  toggleSeriesView: boolean;
}

export interface DailyVisitorsChartActions {
  options: Highcharts.Options;
  comparisonSpaceIds: string[];
  comparisonDateRanges: DateRangeListItem[];
  chartMetaData: DailyVisitorsChartSeriesMetaDataItem[];
  busiestTotal: number | null;
  toggleSeriesView: boolean;
}

export interface HourlyVisitorsChartActions {
  options: Highcharts.Options;
  comparisonSpaceIds: string[];
  comparisonDateRanges: DateRangeListItem[];
  chartMetaData: HourlyVisitorsChartSeriesMetaDataItem[];
  busiestTotal: number | null;
}

export interface DateRangeListItem {
  startDate: Date;
  endDate: Date;
  indexOfDateRange: number;
}

export interface Action {
  type: ActionType;
  value?: AnyVal;
}

export enum ComparisonType {
  DailyOccupancy = "DailyOccupancy",
  HourlyOccupancy = "HourlyOccupancy",
  DailyVisitors = "DailyVisitors",
  HourlyVisitors = "HourlyVisitors",
  DailyDwelltime = "DailyDwelltime",
}

export const initialDailyDwelltimeState = {
  shouldDisplayPeak: false,
  shouldDisplayAverage: true,
  options: initialDateOptions,
  comparisonSpaceIds: [],
  comparisonDateRanges: [],
  chartMetaData: [],
  busiestTotal: null,
  toggleSeriesView: false,
};

export const initialByDayState = {
  shouldDisplayPeak: false,
  shouldDisplayAverage: true,
  isYAxisZoomed: true,
  options: initialDateOptions,
  comparisonSpaceIds: [],
  comparisonDateRanges: [],
  chartMetaData: [],
  busiestTotal: null,
  isYAxisPercentage: false,
  toggleSeriesView: false,
};

export const initialDailyVisitorsState = {
  options: initialDailyVisitorsOptions,
  comparisonSpaceIds: [],
  comparisonDateRanges: [],
  chartMetaData: [],
  busiestTotal: null,
  toggleSeriesView: false,
};

export const initialHourlyVisitorsState = {
  options: initialHourlyVisitorsOptions,
  comparisonSpaceIds: [],
  comparisonDateRanges: [],
  chartMetaData: [],
  busiestTotal: null,
};

export const initialByHourState = {
  shouldDisplayPeak: false,
  shouldDisplayAverage: true,
  isYAxisZoomed: true,
  options: {
    credits: {
      enabled: false,
    },
  },
  comparisonSpaceIds: [],
  comparisonDateRanges: [],
  chartMetaData: [],
  busiestTotal: null,
  isYAxisPercentage: false,
  toggleSeriesView: false,
};

export const initialBasicDateState = {
  options: initialDateOptions,
  data: null,
};

export enum ActionType {
  PageLoading,
  PageLoaded,
  PageError,
  SpaceIdChange,
  TabChange,
  BasicChartClick,
  OccupancyChartTypeChange,
  VisitorsChartTypeChange,
  TabTableShouldShowPeak,
  DailyDwellChartComparisonLoading,
  DailyDwellChartComparisonLoaded,
  DailyDwellChartComparisonError,
  DateChartComparisonOpenClick,
  DailyDwellChartComparisonOpenClick,
  DailyVisitorsChartComparisonOpenClick,
  HourChartComparisonOpenClick,
  HourlyVisitorsChartComparisonOpenClick,
  ComparisonCloseClick,
  OccupancyTabComparisonLoading,
  OccupancyTabComparisonLoaded,
  OccupancyTabComparisonError,
  VisitorsTabComparisonLoading,
  VisitorsTabComparisonLoaded,
  VisitorsTabComparisonError,
  DateSeriesToggle,
  HourSeriesToggle,
  DailyDwellSeriesToggle,
  ByDayYAxisChange,
  ByHourYAxisChange,
  SetCurrentPage,
  TimeRangeChange,
  OmitZeroChange,
  AllWeekdaysChange,
  WeekdaysChange,
  DateRangeChange,
  AddComparisonNodeClick,
  ExportDataOpenClick,
  ExportDataCloseClick,
  OccupancyTabSeriesRemoveClick,
  DailyDwellSeriesRemoveClick,
  VisitorsTabSeriesRemoveClick,
  AddDateTimeComparison,
  DateChartToggleYAxisPercent,
  HourChartToggleYAxisPercent,
  DateChartToggleStacked,
  DailyDwellChartToggleStacked,
  DailyVisitorsChartToggleStacked,
  AddComparisonImportDateChartClick,
  AddComparisonImportHourChartClick,
}

const updateComparisonParams = (
  newSpaceIds: string[],
  newDateRanges: DateRangeListItem[]
) => {
  const params = new URLSearchParams(window.location.search);
  const formattedDates = newDateRanges.map(
    ({ startDate, endDate, indexOfDateRange }) => {
      return {
        endDate: analyticsFilterFormat(endDate),
        startDate: analyticsFilterFormat(startDate),
        indexOfDateRange,
      };
    }
  );

  const stringifiedComparisonDates = JSON.stringify(formattedDates);
  const encodedComparisonDates = encodeURIComponent(stringifiedComparisonDates);
  const encodedComparisonSpaces = encodeURIComponent(newSpaceIds.join(","));
  params.set(
    "comparisonDates",
    formattedDates.length > 0 ? encodedComparisonDates : ""
  );
  params.set(
    "comparisonSpaces",
    newSpaceIds.length > 1 ? encodedComparisonSpaces : ""
  );
  window.history.pushState(
    {},
    "",
    `${window.location.pathname}?${params.toString()}`
  );
};

export const deriveAvailableDates = (
  dateRange: DateRange,
  selectedDays: number[]
): string[] => {
  const { startDate, endDate } = dateRange;

  // Convert JavaScript Date objects to Luxon DateTime objects with no timezone adjustments
  const start = DateTime.fromJSDate(startDate).startOf("day"); // Start of the day
  const end = DateTime.fromJSDate(endDate).endOf("day"); // End of the day

  const availableDates: string[] = [];

  // Iterate through each day within the date range
  let currentDate = start;
  while (currentDate <= end) {
    // Check if the day of the week is selected
    // Adjust if selectedDays is not using ISO convention
    const isoDayOfWeek = (currentDate.weekday % 7) + 1; // Convert to 1-7 range where 1 = Monday and 7 = Sunday

    // Check if the currentDate's weekday is in selectedDays
    if (selectedDays.includes(isoDayOfWeek)) {
      availableDates.push(currentDate.toISODate()); // Formats as yyyy-mm-dd
    }
    // Move to the next day
    currentDate = currentDate.plus({ days: 1 });
  }
  return availableDates;
};

export function reducer(state: State, { type, value }: Action): State {
  switch (type) {
    case ActionType.PageLoading: {
      const newState: State = { ...state };
      const { stateOverrides, showEarlyPreview } = value;
      const heatmapOptions = deriveInitialHeatmapOptions(showEarlyPreview);
      return {
        ...newState,
        tabs: {
          occupancy: {
            ...newState.tabs.occupancy,
            daily: {
              columns: [],
              rows: [],
              groupingModel: [],
            },
            hourly: {
              columns: [],
              rows: [],
              groupingModel: [],
            },
            weekdayAndHour: {
              columns: [],
              rows: [],
            },
          },
          visitors: {
            ...newState.tabs.visitors,
            daily: {
              columns: [],
              rows: [],
              groupingModel: [],
            },
            hourly: {
              columns: [],
              rows: [],
              groupingModel: [],
            },
          },
          dwelltime: {
            daily: {
              columns: [],
              rows: [],
              groupingModel: [],
            },
          },
        },
        dialogs: {
          ...newState.dialogs,
          addComparison: {
            ...newState.dialogs.addComparison,
          },
        },
        dailyOccupancyChart: {
          ...newState.dailyOccupancyChart,
        },
        hourlyOccupancyChart: {
          ...newState.hourlyOccupancyChart,
        },
        dailyVisitors: {
          ...newState.dailyVisitors,
        },
        hourlyVisitors: {
          ...newState.hourlyVisitors,
        },
        byWeekdayAndHourChart: {
          options: heatmapOptions,
        },
        dailyDwelltime: { ...newState.dailyDwelltime },
        pageLoad: {
          occupancyData: null,
          visitorsData: null,
          dwelltimeData: null,
          childSpaces: [],
          isLoading: true,
          error: false,
        },
        ...stateOverrides,
      };
    }
    case ActionType.PageLoaded: {
      const newState = { ...state };
      const {
        dailyOccupancyChart,
        hourlyOccupancyChart,
        dailyDwelltime,
        dailyVisitors,
        hourlyVisitors,
        filters: { dateRange, timeRange, selectedDays },
        tabs: { dwelltime },
      } = newState;

      const {
        nodes,
        spaceId,
        isMobileSized,
        occupancyData,
        visitorsData,
        dwelltimeData,
        showEarlyPreview,
        newShowEarlyPreviewStartDate,
      } = value;
      const availableDates = deriveAvailableDates(
        value.filters.dateRange,
        selectedDays
      );

      const parsedOccupancyDailyData = JSON.parse(
        occupancyData.byDate.chartData
      );
      const parsedOccupancyHourlyData = JSON.parse(
        occupancyData.byHour.chartData
      );
      const parsedDailyVisitorshipData =
        visitorsData && visitorsData.byDate.chartData
          ? JSON.parse(visitorsData.byDate.chartData)
          : null;
      const parsedHourlyVisitorshipData =
        visitorsData && visitorsData.byHour.chartData
          ? JSON.parse(visitorsData.byHour.chartData)
          : null;
      const parsedDailyDwelltimeData =
        dwelltimeData && dwelltimeData.byDate.chartData
          ? JSON.parse(dwelltimeData.byDate.chartData)
          : null;

      const byDateData = parsedOccupancyDailyData[0];

      const capacity = byDateData
        ? value.nodes[Object.keys(byDateData)[0]].capacity
        : 0;

      const isOccupancyDateComparison =
        dailyOccupancyChart.comparisonDateRanges.length > 0;
      const isVisitorsDateComparison =
        dailyVisitors.comparisonDateRanges.length > 0;
      const isDwelltimeDateComparison =
        dailyDwelltime.comparisonDateRanges.length > 0;

      // ==================== WeekdayAndHour SERIES ====================
      const newHeatmapOptions: Highcharts.Options = {};
      if (occupancyData.occupancyByWeekdayAndHour) {
        const parsedHeatmapData = JSON.parse(
          occupancyData.occupancyByWeekdayAndHour
        );

        const legendSize = isMobileSized ? 200 : 400;

        const heatmapSeries = deriveHeatmapSeries(parsedHeatmapData);
        Object.assign(newHeatmapOptions, {
          ...state.byWeekdayAndHourChart.options,
          legend: {
            symbolWidth: legendSize,
          },
          series: [
            {
              name: "Average Occupancy",
              borderWidth: 1,
              borderColor: "white",
              // Values on the heatmap as array [hour, day, value]
              data: heatmapSeries,
            },
          ],
          ...deriveHeatmapTooltip(capacity, showEarlyPreview),
        });
      }

      // ==================== Hour SERIES ====================
      const {
        newHourOptions,
        hourSeriesMetaData,
        dataMax: hourDataMax,
      } = deriveHourChartOptions(
        parsedOccupancyHourlyData,
        isOccupancyDateComparison,
        hourlyOccupancyChart.shouldDisplayAverage,
        hourlyOccupancyChart.shouldDisplayPeak,
        hourlyOccupancyChart.isYAxisZoomed,
        hourlyOccupancyChart.options,
        hourlyOccupancyChart.isYAxisPercentage,
        nodes,
        hourlyOccupancyChart.comparisonSpaceIds,
        hourlyOccupancyChart.comparisonDateRanges,
        hourlyOccupancyChart.chartMetaData,
        dateRange,
        capacity,
        showEarlyPreview
      );

      const { newHourOptions: basicHourlyOccupancyOptions } =
        deriveHourChartOptions(
          parsedOccupancyHourlyData,
          false,
          true,
          false,
          true,
          hourlyOccupancyChart.options,
          false,
          nodes,
          [spaceId.toString()],
          [],
          [],
          dateRange,
          capacity,
          showEarlyPreview
        );

      // ==================== DATE SERIES ====================
      const {
        newOptions: newDateOptions,
        daySeriesMetaData,
        dataMax,
      } = deriveDateChartOptions(
        parsedOccupancyDailyData,
        isOccupancyDateComparison,
        dailyOccupancyChart.shouldDisplayAverage,
        dailyOccupancyChart.shouldDisplayPeak,
        dailyOccupancyChart.isYAxisZoomed,
        dailyOccupancyChart.options,
        dailyOccupancyChart.isYAxisPercentage,
        nodes,
        dailyOccupancyChart.comparisonSpaceIds,
        dailyOccupancyChart.comparisonDateRanges,
        dailyOccupancyChart.chartMetaData,
        dateRange,
        capacity,
        showEarlyPreview
      );

      const { newOptions: basicDailyOccupancyOptions } = deriveDateChartOptions(
        parsedOccupancyDailyData,
        false,
        true,
        false,
        true,
        dailyOccupancyChart.options,
        false,
        nodes,
        [spaceId.toString()],
        [],
        [],
        dateRange,
        capacity,
        showEarlyPreview
      );

      // ==================== DAILY Dwelltime SERIES ====================

      const { newOptions: basicDwellOptions } = deriveDailyDwellChartOptions(
        parsedDailyDwelltimeData,
        false,
        true,
        false,
        dailyDwelltime.options,
        nodes,
        [spaceId.toString()],
        [],
        [],
        dateRange
      );

      const { newOptions: newDwellOptions, dailyDwellSeriesMetaData } =
        deriveDailyDwellChartOptions(
          parsedDailyDwelltimeData,
          isDwelltimeDateComparison,
          dailyDwelltime.shouldDisplayAverage,
          dailyDwelltime.shouldDisplayPeak,
          dailyDwelltime.options,
          nodes,
          dailyDwelltime.comparisonSpaceIds,
          dailyDwelltime.comparisonDateRanges,
          dailyDwelltime.chartMetaData,
          dateRange
        );

      const dailyDwellSeries = dailyDwellSeriesMetaData.map(
        (item) => item.series
      );

      const newDailyDwellOptions = {
        ...initialDailyDwelltimeOptions,
        ...deriveDailyDwellChartSpacestooltip(),
        ...newDwellOptions,
        series: dailyDwellSeries,
      };

      // VISITORS STUFF ========================

      const { newOptions: basicDailyVisitorsOptions } =
        deriveDailyVisitorsChartOptions(
          parsedDailyVisitorshipData,
          false,
          dailyVisitors.options,
          nodes,
          [spaceId.toString()],
          [],
          [],
          dateRange
        );

      const {
        newOptions: newDailyVisitorsOptions,
        dailyVisitorsSeriesMetaData,
        dataMax: dailyVisitorsMax,
      } = deriveDailyVisitorsChartOptions(
        parsedDailyVisitorshipData,
        isVisitorsDateComparison,
        dailyVisitors.options,
        nodes,
        dailyVisitors.comparisonSpaceIds,
        dailyVisitors.comparisonDateRanges,
        dailyVisitors.chartMetaData,
        dateRange
      );

      const { newHourOptions: basicHourlyVisitorsOptions } =
        deriveHourlyVisitorsChartOptions(
          parsedHourlyVisitorshipData,
          false,
          hourlyVisitors.options,
          nodes,
          [spaceId.toString()],
          [],
          [],
          dateRange
        );

      const {
        newHourOptions: newHourlyVisitorsOptions,
        hourlyVisitorsSeriesMetaData,
      } = deriveHourlyVisitorsChartOptions(
        parsedHourlyVisitorshipData,
        isVisitorsDateComparison,
        hourlyVisitors.options,
        nodes,
        hourlyVisitors.comparisonSpaceIds,
        hourlyVisitors.comparisonDateRanges,
        hourlyVisitors.chartMetaData,
        dateRange
      );

      // TABLE STUFF ========================
      const parsedHeatmapData = JSON.parse(
        occupancyData.occupancyByWeekdayAndHour
      );

      const [heatmapRows, heatmapColumns] = deriveHeatmapTableData(
        parsedHeatmapData,
        capacity,
        showEarlyPreview
      );

      const [rows, columns, groupingModel, columnVisibilityModel] =
        deriveTableSpacesComparisonData(
          dateRange,
          availableDates,
          dailyOccupancyChart.comparisonDateRanges,
          isOccupancyDateComparison,
          false,
          dailyOccupancyChart.comparisonSpaceIds,
          parsedOccupancyDailyData,
          nodes,
          isMobileSized,
          ComparisonType.DailyOccupancy,
          showEarlyPreview
        );

      const [
        hourlyRows,
        hourlyColumns,
        hourlyGroupingModel,
        hourlyColumnVisibilityModel,
      ] = deriveTableSpacesComparisonData(
        dateRange,
        availableDates,
        hourlyOccupancyChart.comparisonDateRanges,
        isOccupancyDateComparison,
        false,
        hourlyOccupancyChart.comparisonSpaceIds,
        parsedOccupancyHourlyData,
        nodes,
        isMobileSized,
        ComparisonType.HourlyOccupancy,
        showEarlyPreview
      );

      const [
        dailyVisitorsRows,
        dailyVisitorsColumns,
        dailyVisitorsGroupingModel,
      ] = deriveDailyVisitorsTableSpacesComparisonData(
        dateRange,
        availableDates,
        dailyVisitors.comparisonDateRanges,
        isVisitorsDateComparison,
        dailyVisitors.comparisonSpaceIds,
        parsedDailyVisitorshipData,
        nodes,
        isMobileSized
      );

      const [
        hourlyVisitorsRows,
        hourlyVisitorsColumns,
        hourlyVisitorsGroupingModel,
      ] = deriveHourlyVisitorsTableSpacesComparisonData(
        dateRange,
        availableDates,
        timeRange,
        hourlyVisitors.comparisonDateRanges,
        isVisitorsDateComparison,
        hourlyVisitors.comparisonSpaceIds,
        parsedHourlyVisitorshipData,
        nodes,
        isMobileSized
      );

      const [
        dailyDwelltimeRows,
        dailyDwelltimeColumns,
        dailyDwelltimeGroupingModel,
      ] = deriveDailyDwellTableSpacesComparisonData(
        dateRange,
        availableDates,
        dailyDwelltime.comparisonDateRanges,
        isDwelltimeDateComparison,
        dwelltime.daily.tableShouldShowPeak,
        dailyDwelltime.comparisonSpaceIds,
        parsedDailyDwelltimeData,
        nodes,
        isMobileSized
      );

      return {
        ...state,
        spaceId,
        tabs: {
          ...state.tabs,
          occupancy: {
            ...state.tabs.occupancy,
            daily: {
              columns,
              rows,
              groupingModel,
              tableShouldShowPeak: false,
              columnVisibilityModel: columnVisibilityModel,
            },
            hourly: {
              columns: hourlyColumns,
              rows: hourlyRows,
              groupingModel: hourlyGroupingModel,
              tableShouldShowPeak: false,
              columnVisibilityModel: hourlyColumnVisibilityModel,
            },
            weekdayAndHour: {
              columns: heatmapColumns,
              rows: heatmapRows,
            },
          },
          visitors: {
            ...state.tabs.visitors,
            daily: {
              columns: dailyVisitorsColumns,
              rows: dailyVisitorsRows,
              groupingModel: dailyVisitorsGroupingModel,
            },
            hourly: {
              columns: hourlyVisitorsColumns,
              rows: hourlyVisitorsRows,
              groupingModel: hourlyVisitorsGroupingModel,
            },
          },
          dwelltime: {
            daily: {
              columns: dailyDwelltimeColumns,
              rows: dailyDwelltimeRows,
              groupingModel: dailyDwelltimeGroupingModel,
              tableShouldShowPeak: false,
              columnVisibilityModel: {},
            },
          },
        },
        basicCharts: {
          byDate: { options: basicDailyOccupancyOptions, data: null },
          byHour: { options: basicHourlyOccupancyOptions, data: null },
          byWeekdayAndHour: { options: newHeatmapOptions, data: null },
          visitors: { options: basicDailyVisitorsOptions, data: null },
          hourlyVisitors: { options: basicHourlyVisitorsOptions, data: null },
          dailyDwelltime: { options: basicDwellOptions, data: null },
        },
        dailyOccupancyChart: {
          ...state.dailyOccupancyChart,
          options: newDateOptions,
          chartMetaData: daySeriesMetaData,
          busiestTotal: dataMax,
          isYAxisPercentage: false,
        },
        hourlyOccupancyChart: {
          ...state.hourlyOccupancyChart,
          options: newHourOptions,
          chartMetaData: hourSeriesMetaData,
          busiestTotal: hourDataMax,
          isYAxisPercentage: false,
        },
        dailyVisitors: {
          ...state.dailyVisitors,
          options: newDailyVisitorsOptions,
          chartMetaData: dailyVisitorsSeriesMetaData,
          busiestTotal: dailyVisitorsMax,
        },
        hourlyVisitors: {
          ...state.hourlyVisitors,
          options: newHourlyVisitorsOptions,
          chartMetaData: hourlyVisitorsSeriesMetaData,
        },
        byWeekdayAndHourChart: {
          options: newHeatmapOptions,
        },
        dailyDwelltime: {
          ...state.dailyDwelltime,
          options: newDailyDwellOptions,
          chartMetaData: dailyDwellSeriesMetaData,
        },
        filters: {
          ...state.filters,
          ...value.filters,
        },
        previousFilters: {
          ...state.filters,
          ...value.previousFilters,
        },
        pageLoad: {
          occupancyData,
          visitorsData,
          dwelltimeData,
          childSpaces: [],
          isLoading: false,
          error: false,
          showEarlyPreviewStartDate: newShowEarlyPreviewStartDate,
        },
      };
    }
    case ActionType.PageError: {
      return {
        ...state,
        pageLoad: {
          occupancyData: null,
          visitorsData: null,
          dwelltimeData: null,
          childSpaces: [],
          isLoading: false,
          error: true,
          showEarlyPreviewStartDate: null,
        },
      };
    }
    case ActionType.SpaceIdChange: {
      const { spaceId, nodes } = value as {
        spaceId: number;
        nodes: SpaceNodeByIdMap;
      };
      const { spaceStatus, occupancyActiveDate, occupancy } =
        nodes[spaceId] || {};
      const showEarlyPreview =
        occupancy &&
        isZeroDate(occupancyActiveDate) &&
        spaceStatus === SpaceStatus.Inactive;
      const comparisonsToUse =
        state.previousSpaceId === null
          ? {}
          : {
              comparisonSpaceIds: [spaceId.toString()],
              comparisonDateRanges: [],
            };
      const chartMetaDataToUse =
        state.previousSpaceId === null ? {} : { chartMetaData: [] };
      const newFilters = showEarlyPreview
        ? ({
            ...defaultFilters,
            timeRange: [0, 24],
          } as FiltersState)
        : state.filters;
      return {
        ...state,
        filters: newFilters,
        previousFilters: newFilters,
        currentPage: 1,
        previousSpaceId: spaceId,
        pageLoad: {
          occupancyData: null,
          visitorsData: null,
          dwelltimeData: null,
          childSpaces: [],
          isLoading: true,
          error: false,
          showEarlyPreviewStartDate: null,
        },
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            ...comparisonsToUse,
          },
        },
        dailyOccupancyChart: {
          ...state.dailyOccupancyChart,
          ...comparisonsToUse,
          ...chartMetaDataToUse,
        },
        hourlyOccupancyChart: {
          ...state.hourlyOccupancyChart,
          ...comparisonsToUse,
          ...chartMetaDataToUse,
        },
        dailyVisitors: {
          ...state.dailyVisitors,
          ...comparisonsToUse,
          ...chartMetaDataToUse,
        },
        hourlyVisitors: {
          ...state.hourlyVisitors,
          ...comparisonsToUse,
          ...chartMetaDataToUse,
        },
        dailyDwelltime: {
          ...state.dailyDwelltime,
          ...comparisonsToUse,
          ...chartMetaDataToUse,
        },
      };
    }
    case ActionType.TabChange: {
      return {
        ...state,
        currentTab: value as AnalyticsRoute,
      };
    }
    case ActionType.BasicChartClick: {
      const { newCurrentTab, newTab, chartType } = value as {
        newCurrentTab: AnalyticsRoute;
        newTab: keyof State["tabs"];
        chartType: ChartType;
      };
      if (newTab === "dwelltime") {
        return {
          ...state,
          currentTab: newCurrentTab,
        };
      }
      return {
        ...state,
        currentTab: newCurrentTab,
        tabs: {
          ...state.tabs,
          [newTab]: {
            ...state.tabs[newTab],
            chartType: chartType,
          },
        },
      };
    }
    case ActionType.OccupancyChartTypeChange: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          occupancy: {
            ...state.tabs.occupancy,
            chartType: value as ChartType,
          },
        },
      };
    }
    case ActionType.VisitorsChartTypeChange: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          visitors: {
            ...state.tabs.visitors,
            chartType: value as ChartType,
          },
        },
      };
    }
    case ActionType.TabTableShouldShowPeak: {
      const newState = { ...state };
      const {
        tabs: {
          occupancy: { daily, hourly },
        },
        dailyOccupancyChart,
        hourlyOccupancyChart,
      } = newState;
      const { tabName, isMobileSized } = value;

      if (tabName === "daily") {
        const newChecked = !daily.tableShouldShowPeak;
        newState.tabs.occupancy.daily.tableShouldShowPeak = newChecked;

        const columnVisibilityModel = deriveTableVisibilityModel(
          dailyOccupancyChart.comparisonSpaceIds,
          dailyOccupancyChart.comparisonDateRanges,
          newChecked,
          isMobileSized
        );
        newState.tabs.occupancy.daily.columnVisibilityModel =
          columnVisibilityModel;
      }
      if (tabName === "hourly") {
        const newChecked = !hourly.tableShouldShowPeak;
        newState.tabs.occupancy.hourly.tableShouldShowPeak = newChecked;

        // TODO: verify
        const columnVisibilityModel = deriveTableVisibilityModel(
          hourlyOccupancyChart.comparisonSpaceIds,
          hourlyOccupancyChart.comparisonDateRanges,
          newChecked,
          isMobileSized
        );
        newState.tabs.occupancy.hourly.columnVisibilityModel =
          columnVisibilityModel;
      }
      return newState;
    }
    case ActionType.DailyDwellChartComparisonLoading: {
      const newState = { ...state };
      const {
        dialogs: { addComparison },
      } = newState;
      return {
        ...newState,
        pageLoad: {
          ...newState.pageLoad,
          isLoading: true,
        },
        dailyDwelltime: {
          ...newState.dailyDwelltime,
        },
        dialogs: {
          ...newState.dialogs,
          addComparison: {
            ...addComparison,
            isLoading: true,
          },
        },
      };
    }
    case ActionType.DailyDwellChartComparisonLoaded: {
      const newState = { ...state };
      const {
        dailyDwelltime: {
          options,
          chartMetaData,
          shouldDisplayAverage,
          shouldDisplayPeak,
        },
        tabs: {
          dwelltime: { daily },
        },
        dialogs: { addComparison },
        filters: { dateRange, selectedDays },
      } = newState;
      const { data, nodes, isDateComparison, isMobileSized } = value;
      const parsedData = JSON.parse(data.byDate.chartData);
      const availableDates = deriveAvailableDates(dateRange, selectedDays);

      const { newOptions, dailyDwellSeriesMetaData } =
        deriveDailyDwellChartOptions(
          parsedData,
          isDateComparison,
          shouldDisplayAverage,
          shouldDisplayPeak,
          options,
          nodes,
          addComparison.comparisonSpaceIds,
          addComparison.comparisonDateRanges,
          chartMetaData,
          dateRange
        );

      const [
        dailyDwelltimeRows,
        dailyDwelltimeColumns,
        dailyDwelltimeGroupingModel,
      ] = deriveDailyDwellTableSpacesComparisonData(
        dateRange,
        availableDates,
        addComparison.comparisonDateRanges,
        isDateComparison,
        daily.tableShouldShowPeak,
        addComparison.comparisonSpaceIds,
        parsedData,
        nodes,
        isMobileSized
      );

      return {
        ...state,
        tabs: {
          ...state.tabs,
          dwelltime: {
            daily: {
              columns: dailyDwelltimeColumns,
              rows: dailyDwelltimeRows,
              groupingModel: dailyDwelltimeGroupingModel,
              tableShouldShowPeak: false,
              columnVisibilityModel: {},
            },
          },
        },
        dailyDwelltime: {
          ...state.dailyDwelltime,
          options: newOptions,
          chartMetaData: dailyDwellSeriesMetaData,
          comparisonDateRanges: addComparison.comparisonDateRanges,
          comparisonSpaceIds: addComparison.comparisonSpaceIds,
        },
        selectedComparisonSpaceNode: undefined,
        pageLoad: {
          ...state.pageLoad,
          isLoading: false,
          dwelltimeData: data,
        },
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...addComparison,
            comparisonDateRanges: [],
            comparisonSpaceIds: [],
            isLoading: false,
            isOpen: false,
          },
        },
      };
    }
    case ActionType.DailyDwellChartComparisonError: {
      return {
        ...state,
        pageLoad: {
          ...state.pageLoad,
          isLoading: false,
          error: true,
        },
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            isLoading: false,
            errorMessage: "Something went wrong",
          },
        },
      };
    }
    case ActionType.OccupancyTabComparisonLoading: {
      const newState = { ...state };
      const {
        dialogs: { addComparison },
      } = newState;
      return {
        ...newState,
        pageLoad: {
          ...newState.pageLoad,
          isLoading: true,
        },
        dailyOccupancyChart: {
          ...newState.dailyOccupancyChart,
        },
        hourlyOccupancyChart: {
          ...newState.hourlyOccupancyChart,
        },
        dialogs: {
          ...newState.dialogs,
          addComparison: {
            ...addComparison,
            isLoading: true,
          },
        },
      };
    }
    case ActionType.OccupancyTabComparisonLoaded: {
      const newState = { ...state };
      const {
        dailyOccupancyChart,
        hourlyOccupancyChart,
        dialogs: { addComparison },
        filters: { dateRange, selectedDays },
      } = newState;

      const {
        data,
        capacity,
        nodes,
        isDateComparison,
        isMobileSized,
        showEarlyPreview,
      } = value;
      const parsedByDateData = JSON.parse(data.byDate.chartData);
      const parsedByHourData = JSON.parse(data.byHour.chartData);
      const availableDates = deriveAvailableDates(dateRange, selectedDays);

      //
      const { newOptions, daySeriesMetaData, dataMax } = deriveDateChartOptions(
        parsedByDateData,
        isDateComparison,
        dailyOccupancyChart.shouldDisplayAverage,
        dailyOccupancyChart.shouldDisplayPeak,
        dailyOccupancyChart.isYAxisZoomed,
        dailyOccupancyChart.options,
        dailyOccupancyChart.isYAxisPercentage,
        nodes,
        addComparison.comparisonSpaceIds,
        addComparison.comparisonDateRanges,
        dailyOccupancyChart.chartMetaData,
        dateRange,
        capacity,
        showEarlyPreview
      );
      const [rows, columns, groupingModel, columnVisibilityModel] =
        deriveTableSpacesComparisonData(
          dateRange,
          availableDates,
          addComparison.comparisonDateRanges,
          isDateComparison,
          false,
          addComparison.comparisonSpaceIds,
          parsedByDateData,
          nodes,
          isMobileSized,
          ComparisonType.DailyOccupancy,
          showEarlyPreview
        );

      const { newHourOptions, hourSeriesMetaData } = deriveHourChartOptions(
        parsedByHourData,
        isDateComparison,
        hourlyOccupancyChart.shouldDisplayAverage,
        hourlyOccupancyChart.shouldDisplayPeak,
        hourlyOccupancyChart.isYAxisZoomed,
        hourlyOccupancyChart.options,
        hourlyOccupancyChart.isYAxisPercentage,
        nodes,
        addComparison.comparisonSpaceIds,
        addComparison.comparisonDateRanges,
        hourlyOccupancyChart.chartMetaData,
        dateRange,
        capacity,
        showEarlyPreview
      );

      const [
        hourlyRows,
        hourlyColumns,
        hourlyGroupingModel,
        hourlyColumnVisibilityModel,
      ] = deriveTableSpacesComparisonData(
        dateRange,
        availableDates,
        addComparison.comparisonDateRanges,
        isDateComparison,
        false,
        addComparison.comparisonSpaceIds,
        parsedByHourData,
        nodes,
        isMobileSized,
        ComparisonType.HourlyOccupancy,
        showEarlyPreview
      );
      return {
        ...state,
        tabs: {
          ...state.tabs,
          occupancy: {
            ...state.tabs.occupancy,
            daily: {
              columns,
              rows,
              groupingModel,
              tableShouldShowPeak: false,
              columnVisibilityModel,
            },
            hourly: {
              columns: hourlyColumns,
              rows: hourlyRows,
              groupingModel: hourlyGroupingModel,
              tableShouldShowPeak: false,
              columnVisibilityModel: hourlyColumnVisibilityModel,
            },
          },
        },
        hourlyOccupancyChart: {
          ...state.hourlyOccupancyChart,
          options: newHourOptions,
          chartMetaData: hourSeriesMetaData,
          comparisonDateRanges: addComparison.comparisonDateRanges,
          comparisonSpaceIds: addComparison.comparisonSpaceIds,
          isYAxisPercentage: isDateComparison
            ? false
            : hourlyOccupancyChart.isYAxisPercentage,
        },
        dailyOccupancyChart: {
          ...state.dailyOccupancyChart,
          options: newOptions,
          comparisonDateRanges: addComparison.comparisonDateRanges,
          comparisonSpaceIds: addComparison.comparisonSpaceIds,
          chartMetaData: daySeriesMetaData,
          isYAxisPercentage: isDateComparison
            ? false
            : dailyOccupancyChart.isYAxisPercentage,
          busiestTotal: dataMax,
        },
        pageLoad: {
          ...state.pageLoad,
          occupancyData: value.data,
          isLoading: false,
          error: false,
        },
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            isLoading: false,
            isOpen: false,
            comparisonDateRanges: [],
            comparisonSpaceIds: [],
          },
        },
      };
    }
    case ActionType.OccupancyTabComparisonError: {
      return {
        ...state,
        pageLoad: {
          ...state.pageLoad,
          isLoading: false,
          error: true,
        },
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            isLoading: false,
            errorMessage: "Something went wrong",
          },
        },
      };
    }
    case ActionType.VisitorsTabComparisonLoading: {
      const newState = { ...state };
      const {
        dialogs: { addComparison },
      } = newState;
      return {
        ...newState,
        pageLoad: {
          ...newState.pageLoad,
          isLoading: true,
        },
        dailyVisitors: {
          ...newState.dailyVisitors,
        },
        dialogs: {
          ...newState.dialogs,
          addComparison: {
            ...addComparison,
            isLoading: true,
          },
        },
      };
    }
    case ActionType.VisitorsTabComparisonLoaded: {
      const newState = { ...state };
      const {
        dailyVisitors,
        hourlyVisitors,
        dialogs: { addComparison },
        filters: { dateRange, selectedDays, timeRange },
      } = newState;
      const { data, nodes, isDateComparison, isMobileSized } = value;
      const parsedByDateData = JSON.parse(data.byDate.chartData);
      const parsedByHourData = JSON.parse(data.byHour.chartData);
      const availableDates = deriveAvailableDates(dateRange, selectedDays);

      const { newOptions, dailyVisitorsSeriesMetaData, dataMax } =
        deriveDailyVisitorsChartOptions(
          parsedByDateData,
          isDateComparison,
          dailyVisitors.options,
          nodes,
          addComparison.comparisonSpaceIds,
          addComparison.comparisonDateRanges,
          dailyVisitors.chartMetaData,
          dateRange
        );

      const [
        dailyVisitorsRows,
        dailyVisitorsColumns,
        dailyVisitorsGroupingModel,
      ] = deriveDailyVisitorsTableSpacesComparisonData(
        dateRange,
        availableDates,
        addComparison.comparisonDateRanges,
        isDateComparison,
        addComparison.comparisonSpaceIds,
        parsedByDateData,
        nodes,
        isMobileSized
      );

      const { newHourOptions, hourlyVisitorsSeriesMetaData } =
        deriveHourlyVisitorsChartOptions(
          parsedByHourData,
          isDateComparison,
          hourlyVisitors.options,
          nodes,
          addComparison.comparisonSpaceIds,
          addComparison.comparisonDateRanges,
          hourlyVisitors.chartMetaData,
          dateRange
        );

      const [
        hourlyVisitorsRows,
        hourlyVisitorsColumns,
        hourlyVisitorsGroupingModel,
      ] = deriveHourlyVisitorsTableSpacesComparisonData(
        dateRange,
        availableDates,
        timeRange,
        addComparison.comparisonDateRanges,
        isDateComparison,
        addComparison.comparisonSpaceIds,
        parsedByHourData,
        nodes,
        isMobileSized
      );
      return {
        ...state,
        tabs: {
          ...state.tabs,
          visitors: {
            ...state.tabs.visitors,
            daily: {
              columns: dailyVisitorsColumns,
              rows: dailyVisitorsRows,
              groupingModel: dailyVisitorsGroupingModel,
            },
            hourly: {
              columns: hourlyVisitorsColumns,
              rows: hourlyVisitorsRows,
              groupingModel: hourlyVisitorsGroupingModel,
            },
          },
        },
        dailyVisitors: {
          ...state.dailyVisitors,
          options: newOptions,
          comparisonDateRanges: addComparison.comparisonDateRanges,
          comparisonSpaceIds: addComparison.comparisonSpaceIds,
          chartMetaData: dailyVisitorsSeriesMetaData,
          busiestTotal: dataMax,
        },
        hourlyVisitors: {
          ...state.hourlyVisitors,
          options: newHourOptions,
          comparisonDateRanges: addComparison.comparisonDateRanges,
          comparisonSpaceIds: addComparison.comparisonSpaceIds,
          chartMetaData: hourlyVisitorsSeriesMetaData,
        },
        selectedComparisonSpaceNode: undefined,
        pageLoad: {
          ...state.pageLoad,
          isLoading: false,
          visitorsData: data,
        },
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...addComparison,
            comparisonDateRanges: [],
            comparisonSpaceIds: [],
            isLoading: false,
            isOpen: false,
          },
        },
      };
    }
    case ActionType.VisitorsTabComparisonError: {
      // TODO: comsolidate into ComparisonError
      return {
        ...state,
        pageLoad: {
          ...state.pageLoad,
          isLoading: false,
          error: true,
        },
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            isLoading: false,
            errorMessage: "Something went wrong",
          },
        },
      };
    }
    case ActionType.DateChartComparisonOpenClick: {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            comparisonSpaceIds: state.dailyOccupancyChart.comparisonSpaceIds,
            comparisonDateRanges:
              state.dailyOccupancyChart.comparisonDateRanges,
            initialComparisonSpaceIds:
              state.dailyOccupancyChart.comparisonSpaceIds,
            initialComparisonDateRanges:
              state.dailyOccupancyChart.comparisonDateRanges,
            otherChartComparisonDateRanges:
              state.hourlyOccupancyChart.comparisonDateRanges,
            otherChartComparisonSpaceIds:
              state.hourlyOccupancyChart.comparisonSpaceIds,
            isOpen: true,
            comparisonType: ComparisonType.DailyOccupancy,
          },
        },
      };
    }
    case ActionType.DailyDwellChartComparisonOpenClick: {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            comparisonSpaceIds: state.dailyDwelltime.comparisonSpaceIds,
            comparisonDateRanges: state.dailyDwelltime.comparisonDateRanges,
            initialComparisonSpaceIds: state.dailyDwelltime.comparisonSpaceIds,
            initialComparisonDateRanges:
              state.dailyDwelltime.comparisonDateRanges,
            isOpen: true,
            comparisonType: ComparisonType.DailyDwelltime,
          },
        },
      };
    }
    case ActionType.DailyVisitorsChartComparisonOpenClick: {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            comparisonSpaceIds: state.dailyVisitors.comparisonSpaceIds,
            comparisonDateRanges: state.dailyVisitors.comparisonDateRanges,
            initialComparisonSpaceIds: state.dailyVisitors.comparisonSpaceIds,
            initialComparisonDateRanges:
              state.dailyVisitors.comparisonDateRanges,
            isOpen: true,
            comparisonType: ComparisonType.DailyVisitors,
          },
        },
      };
    }
    case ActionType.HourChartComparisonOpenClick: {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            comparisonSpaceIds: state.hourlyOccupancyChart.comparisonSpaceIds,
            comparisonDateRanges:
              state.hourlyOccupancyChart.comparisonDateRanges,
            initialComparisonSpaceIds:
              state.hourlyOccupancyChart.comparisonSpaceIds,
            initialComparisonDateRanges:
              state.hourlyOccupancyChart.comparisonDateRanges,
            otherChartComparisonDateRanges:
              state.dailyOccupancyChart.comparisonDateRanges,
            otherChartComparisonSpaceIds:
              state.dailyOccupancyChart.comparisonSpaceIds,
            isOpen: true,
            comparisonType: ComparisonType.HourlyOccupancy,
          },
        },
      };
    }
    case ActionType.HourlyVisitorsChartComparisonOpenClick: {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            comparisonSpaceIds: state.hourlyVisitors.comparisonSpaceIds,
            comparisonDateRanges: state.hourlyVisitors.comparisonDateRanges,
            initialComparisonSpaceIds: state.hourlyVisitors.comparisonSpaceIds,
            initialComparisonDateRanges:
              state.hourlyVisitors.comparisonDateRanges,
            isOpen: true,
            comparisonType: ComparisonType.HourlyVisitors,
          },
        },
      };
    }
    case ActionType.ComparisonCloseClick: {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          addComparison: {
            ...state.dialogs.addComparison,
            comparisonSpaceIds: [],
            comparisonDateRanges: [],
            initialComparisonSpaceIds: [],
            initialComparisonDateRanges: [],
            isOpen: false,
          },
        },
      };
    }
    case ActionType.DateSeriesToggle: {
      const newState = { ...state };
      const { series } = value;
      const {
        dailyOccupancyChart: { shouldDisplayAverage, shouldDisplayPeak },
      } = newState;
      let newDisplayPeak = shouldDisplayPeak;
      let newDisplayAverage = shouldDisplayAverage;
      if (series === "Peak") {
        newDisplayPeak = !newDisplayPeak;
        const params = new URLSearchParams(window.location.search);
        params.set("showPeak", newDisplayPeak === true ? "true" : "false");
        window.history.pushState(
          {},
          "",
          `${window.location.pathname}?${params.toString()}`
        );
      } else if (series === "Average") {
        newDisplayAverage = !newDisplayAverage;
      }
      if (!newState.dailyOccupancyChart.options.series) return newState;

      const newSeries = newState.dailyOccupancyChart.options.series.map((s) => {
        if (s.name?.includes("Average")) {
          if (newDisplayAverage) {
            return { ...s, visible: true };
          } else {
            return { ...s, visible: false };
          }
        }
        if (s.name?.includes("Peak")) {
          if (newDisplayPeak) {
            return { ...s, visible: true };
          } else {
            return { ...s, visible: false };
          }
        }
      });

      return {
        ...newState,
        dailyOccupancyChart: {
          ...newState.dailyOccupancyChart,
          options: {
            ...newState.dailyOccupancyChart.options,
            series: newSeries as AnyVal,
          },
          shouldDisplayAverage: newDisplayAverage,
          shouldDisplayPeak: newDisplayPeak,
        },
      };
    }
    case ActionType.HourSeriesToggle: {
      const newState = { ...state };

      const { series } = value;
      const {
        hourlyOccupancyChart: { shouldDisplayAverage, shouldDisplayPeak },
      } = newState;
      let newDisplayPeak = shouldDisplayPeak;
      let newDisplayAverage = shouldDisplayAverage;
      if (series === "Peak") {
        newDisplayPeak = !newDisplayPeak;
        const params = new URLSearchParams(window.location.search);
        params.set("showPeak", newDisplayPeak === true ? "true" : "false");
        window.history.pushState(
          {},
          "",
          `${window.location.pathname}?${params.toString()}`
        );
      } else if (series === "Average") {
        newDisplayAverage = !newDisplayAverage;
      }
      if (!newState.hourlyOccupancyChart.options.series) return newState;

      const newSeries = newState.hourlyOccupancyChart.options.series.map(
        (s) => {
          if (s.name?.includes("Average")) {
            if (newDisplayAverage) {
              return { ...s, visible: true };
            } else {
              return { ...s, visible: false };
            }
          }
          if (s.name?.includes("Peak")) {
            if (newDisplayPeak) {
              return { ...s, visible: true };
            } else {
              return { ...s, visible: false };
            }
          }
        }
      );

      return {
        ...newState,
        hourlyOccupancyChart: {
          ...newState.hourlyOccupancyChart,
          options: {
            ...newState.hourlyOccupancyChart.options,
            series: newSeries as AnyVal,
          },
          shouldDisplayAverage: newDisplayAverage,
          shouldDisplayPeak: newDisplayPeak,
        },
      };
    }
    case ActionType.DailyDwellSeriesToggle: {
      const newState = { ...state };

      const { series } = value;
      const {
        dailyDwelltime: { shouldDisplayAverage, shouldDisplayPeak },
      } = newState;
      let newDisplayPeak = shouldDisplayPeak;
      let newDisplayAverage = shouldDisplayAverage;
      if (series === "Peak") {
        const params = new URLSearchParams(window.location.search);
        params.set("showPeak", !newDisplayPeak === true ? "true" : "false");
        window.history.pushState(
          {},
          "",
          `${window.location.pathname}?${params.toString()}`
        );
        newDisplayPeak = !newDisplayPeak;
      } else if (series === "Average") {
        newDisplayAverage = !newDisplayAverage;
      }
      if (!newState.dailyDwelltime.options.series) return newState;

      const newSeries = newState.dailyDwelltime.options.series.map((s) => {
        if (s.name?.includes("Average")) {
          if (newDisplayAverage) {
            return { ...s, visible: true };
          } else {
            return { ...s, visible: false };
          }
        }
        if (s.name?.includes("Peak")) {
          if (newDisplayPeak) {
            return { ...s, visible: true };
          } else {
            return { ...s, visible: false };
          }
        }
      });

      return {
        ...newState,
        dailyDwelltime: {
          ...newState.dailyDwelltime,
          options: {
            ...newState.dailyDwelltime.options,
            series: newSeries as AnyVal,
          },
          shouldDisplayAverage: newDisplayAverage,
          shouldDisplayPeak: newDisplayPeak,
        },
      };
    }
    case ActionType.ByDayYAxisChange: {
      const newState = { ...state };
      const { dailyOccupancyChart } = newState;
      const { checked, capacity, showEarlyPreview } = value;

      const yAxisMax = deriveChartYAxisMax(
        dailyOccupancyChart.isYAxisPercentage,
        checked,
        capacity,
        dailyOccupancyChart.busiestTotal
      );

      const showSecondYAxis = Boolean(
        dailyOccupancyChart.comparisonSpaceIds.length === 1
      );

      const yAxisOptions = deriveDefaultYAxisOptions(
        yAxisMax,
        capacity,
        showSecondYAxis,
        checked,
        dailyOccupancyChart.isYAxisPercentage,
        showEarlyPreview
      );

      return {
        ...newState,
        dailyOccupancyChart: {
          ...newState.dailyOccupancyChart,
          isYAxisZoomed: checked,
          options: {
            ...newState.dailyOccupancyChart.options,
            ...yAxisOptions,
          },
        },
      };
    }
    case ActionType.ByHourYAxisChange: {
      const newState = { ...state };
      const { hourlyOccupancyChart } = newState;
      const { checked, capacity, showEarlyPreview } = value;

      const yAxisMax = deriveChartYAxisMax(
        hourlyOccupancyChart.isYAxisPercentage,
        checked,
        capacity,
        hourlyOccupancyChart.busiestTotal
      );

      const showSecondYAxis = Boolean(
        hourlyOccupancyChart.comparisonSpaceIds.length === 1
      );

      const yAxisOptions = deriveDefaultYAxisOptions(
        yAxisMax,
        capacity,
        showSecondYAxis,
        checked,
        hourlyOccupancyChart.isYAxisPercentage,
        showEarlyPreview
      );
      return {
        ...newState,
        hourlyOccupancyChart: {
          ...newState.hourlyOccupancyChart,
          isYAxisZoomed: checked,
          options: {
            ...newState.hourlyOccupancyChart.options,
            ...yAxisOptions,
          },
        },
      };
    }
    case ActionType.SetCurrentPage: {
      const newState = { ...state };
      newState.currentPage = value;
      return newState;
    }
    case ActionType.TimeRangeChange: {
      const newState = { ...state };
      newState.filters = getTimeRangeChangeState(
        state.filters,
        value as number[]
      );
      return newState;
    }
    case ActionType.OmitZeroChange: {
      const newState = { ...state };
      newState.filters.omitZero = value;
      return newState;
    }
    case ActionType.AllWeekdaysChange: {
      const newState = { ...state };
      newState.filters.selectedDays = value;
      return newState;
    }
    case ActionType.WeekdaysChange: {
      const newState = { ...state };
      newState.filters.selectedDays = value;
      newState.filters.hasChangedDays = true;
      return newState;
    }
    case ActionType.DateRangeChange: {
      const newState = { ...state };
      newState.filters.dateRange = value;
      newState.filters.hasChangedDates = true;
      return newState;
    }
    case ActionType.AddComparisonNodeClick: {
      const newState = { ...state };
      const {
        dialogs: { addComparison },
      } = newState;
      addComparison.comparisonDateRanges = [];
      if (addComparison.comparisonSpaceIds.includes(value)) {
        addComparison.comparisonSpaceIds =
          addComparison.comparisonSpaceIds.filter(
            (item: string) => item !== value
          );
      } else if (addComparison.comparisonSpaceIds.length >= 5) {
        return newState;
      } else {
        addComparison.comparisonSpaceIds = [
          ...addComparison.comparisonSpaceIds,
          value,
        ];
      }
      return newState;
    }
    case ActionType.ExportDataOpenClick: {
      const newState = { ...state };
      newState.filters.selectedSpaceIds = value;
      newState.dialogs.exportDataDialog.isOpen = true;
      return newState;
    }
    case ActionType.ExportDataCloseClick: {
      const newState = { ...state };
      newState.dialogs.exportDataDialog.isOpen = false;
      return newState;
    }
    case ActionType.OccupancyTabSeriesRemoveClick: {
      const newState = { ...state };
      const { isMobileSized, showEarlyPreview } = value;
      const {
        dailyOccupancyChart,
        hourlyOccupancyChart,
        filters: { dateRange, selectedDays },
        nodes,
        pageLoad: { occupancyData },
        tabs: {
          occupancy: { daily, hourly },
        },
        spaceId,
      } = newState;

      if (
        !dailyOccupancyChart?.options.series?.length ||
        !hourlyOccupancyChart?.options?.series?.length ||
        !occupancyData
      )
        return newState;
      const deriveUpdatedState = () => {
        if (dailyOccupancyChart.comparisonDateRanges.length) {
          const newDateRanges = dailyOccupancyChart.comparisonDateRanges
            .filter(
              (range) =>
                deriveComparisonDatesLabel(range.startDate, range.endDate) !==
                value.name
            )
            .map((date: DateRangeListItem, index: number) => ({
              ...date,
              indexOfDateRange: index + 1,
            }));
          return {
            newSpaceIds: dailyOccupancyChart.comparisonSpaceIds,
            newDateRanges,
          };
        } else {
          const newSpaceIds = dailyOccupancyChart.comparisonSpaceIds.filter(
            (item: string) => item !== String(value.id)
          );
          return {
            newSpaceIds,
            newDateRanges: dailyOccupancyChart.comparisonDateRanges,
          };
        }
      };

      const { newSpaceIds, newDateRanges } = deriveUpdatedState();
      updateComparisonParams(newSpaceIds, newDateRanges);
      const availableDates = deriveAvailableDates(dateRange, selectedDays);
      const parsedByDateData = JSON.parse(occupancyData.byDate.chartData);
      const parsedByHourData = JSON.parse(occupancyData.byHour.chartData);
      const isDateComparison = Boolean(newDateRanges.length);
      const capacity = nodes[spaceId as number].capacity;
      const { newOptions, daySeriesMetaData, dataMax } = deriveDateChartOptions(
        parsedByDateData,
        isDateComparison,
        dailyOccupancyChart.shouldDisplayAverage,
        dailyOccupancyChart.shouldDisplayPeak,
        dailyOccupancyChart.isYAxisZoomed,
        dailyOccupancyChart.options,
        newSpaceIds.length === 1
          ? false
          : dailyOccupancyChart.isYAxisPercentage,
        nodes,
        newSpaceIds,
        newDateRanges,
        dailyOccupancyChart.chartMetaData,
        dateRange,
        capacity,
        showEarlyPreview
      );
      const [rows, columns, groupingModel, columnVisibilityModel] =
        deriveTableSpacesComparisonData(
          dateRange,
          availableDates,
          newDateRanges,
          isDateComparison,
          daily.tableShouldShowPeak,
          newSpaceIds,
          parsedByDateData,
          nodes,
          isMobileSized,
          ComparisonType.DailyOccupancy,
          showEarlyPreview
        );
      const {
        newHourOptions,
        hourSeriesMetaData,
        dataMax: hourlyDataMax,
      } = deriveHourChartOptions(
        parsedByHourData,
        isDateComparison,
        hourlyOccupancyChart.shouldDisplayAverage,
        hourlyOccupancyChart.shouldDisplayPeak,
        hourlyOccupancyChart.isYAxisZoomed,
        hourlyOccupancyChart.options,
        newSpaceIds.length === 1
          ? false
          : hourlyOccupancyChart.isYAxisPercentage,
        nodes,
        newSpaceIds,
        newDateRanges,
        hourlyOccupancyChart.chartMetaData,
        dateRange,
        capacity,
        showEarlyPreview
      );
      const [
        hourlyRows,
        hourlyColumns,
        hourlyGroupingModel,
        hourlyColumnVisibilityModel,
      ] = deriveTableSpacesComparisonData(
        dateRange,
        availableDates,
        newDateRanges,
        isDateComparison,
        hourly.tableShouldShowPeak,
        newSpaceIds,
        parsedByHourData,
        nodes,
        isMobileSized,
        ComparisonType.HourlyOccupancy,
        showEarlyPreview
      );

      return {
        ...newState,
        tabs: {
          ...newState.tabs,
          occupancy: {
            ...state.tabs.occupancy,
            hourly: {
              ...newState.tabs.occupancy.hourly,
              columns: hourlyColumns,
              rows: hourlyRows,
              groupingModel: hourlyGroupingModel,
              columnVisibilityModel: hourlyColumnVisibilityModel,
            },
            daily: {
              ...newState.tabs.occupancy.daily,
              columns,
              rows,
              groupingModel,
              columnVisibilityModel,
            },
          },
        },
        hourlyOccupancyChart: {
          ...newState.hourlyOccupancyChart,
          options: newHourOptions,
          comparisonSpaceIds: newSpaceIds,
          comparisonDateRanges: newDateRanges,
          chartMetaData: hourSeriesMetaData,
          busiestTotal: hourlyDataMax,
          isYAxisPercentage:
            newSpaceIds.length === 1
              ? false
              : hourlyOccupancyChart.isYAxisPercentage,
        },
        dailyOccupancyChart: {
          ...newState.dailyOccupancyChart,
          options: newOptions,
          comparisonSpaceIds: newSpaceIds,
          comparisonDateRanges: newDateRanges,
          chartMetaData: daySeriesMetaData,
          busiestTotal: dataMax,
          isYAxisPercentage:
            newSpaceIds.length === 1
              ? false
              : dailyOccupancyChart.isYAxisPercentage,
        },
      };
    }
    case ActionType.DailyDwellSeriesRemoveClick: {
      const newState = { ...state };
      const { isMobileSized } = value;
      const {
        dailyDwelltime: {
          chartMetaData,
          comparisonDateRanges,
          comparisonSpaceIds,
          shouldDisplayAverage,
          options,
        },
        filters: { dateRange, selectedDays },
        nodes,
        pageLoad: { dwelltimeData },
        tabs: {
          dwelltime: { daily },
        },
      } = newState;
      if (!options?.series?.length) return newState;
      const deriveUpdatedState = () => {
        if (comparisonDateRanges.length) {
          const newDateRanges = comparisonDateRanges
            .filter(
              (range) =>
                deriveComparisonDatesLabel(range.startDate, range.endDate) !==
                value.name
            )
            .map((date: DateRangeListItem, index: number) => ({
              ...date,
              indexOfDateRange: index + 1,
            }));
          return {
            newSpaceIds: comparisonSpaceIds,
            newDateRanges,
          };
        } else {
          const newSpaceIds = comparisonSpaceIds.filter(
            (item: string) => item !== String(value.id)
          );
          return {
            newSpaceIds,
            newDateRanges: comparisonDateRanges,
          };
        }
      };
      const { newSpaceIds, newDateRanges } = deriveUpdatedState();
      updateComparisonParams(newSpaceIds, newDateRanges);
      const availableDates = deriveAvailableDates(dateRange, selectedDays);
      const parsedData = dwelltimeData
        ? JSON.parse(dwelltimeData.byDate.chartData)
        : null;
      const isDateComparison = Boolean(newDateRanges.length);
      const { newOptions, dailyDwellSeriesMetaData } =
        deriveDailyDwellChartOptions(
          parsedData,
          isDateComparison,
          shouldDisplayAverage,
          false,
          options,
          nodes,
          newSpaceIds,
          newDateRanges,
          chartMetaData,
          dateRange
        );

      const [rows, columns, groupingModel, columnVisibilityModel] =
        deriveDailyDwellTableSpacesComparisonData(
          dateRange,
          availableDates,
          newDateRanges,
          isDateComparison,
          daily.tableShouldShowPeak,
          newSpaceIds,
          parsedData,
          nodes,
          isMobileSized
        );

      return {
        ...newState,
        tabs: {
          ...newState.tabs,
          dwelltime: {
            ...newState.tabs.dwelltime,
            daily: {
              ...newState.tabs.dwelltime.daily,
              columns,
              rows,
              groupingModel,
              columnVisibilityModel,
            },
          },
        },
        dailyDwelltime: {
          ...newState.dailyOccupancyChart,
          options: newOptions,
          comparisonSpaceIds: newSpaceIds,
          comparisonDateRanges: newDateRanges,
          chartMetaData: dailyDwellSeriesMetaData,
        },
      };
    }
    case ActionType.VisitorsTabSeriesRemoveClick: {
      const newState = { ...state };
      const { isMobileSized } = value;
      const {
        dailyVisitors,
        hourlyVisitors,
        filters: { dateRange, selectedDays, timeRange },
        nodes,
        pageLoad: { visitorsData },
      } = newState;

      if (
        (!dailyVisitors.options?.series?.length &&
          !hourlyVisitors.options?.series?.length) ||
        !visitorsData
      ) {
        return newState;
      }
      const deriveUpdatedState = () => {
        if (dailyVisitors.comparisonDateRanges.length) {
          const newDateRanges = dailyVisitors.comparisonDateRanges
            .filter(
              (range) =>
                deriveComparisonDatesLabel(range.startDate, range.endDate) !==
                value.name
            )
            .map((date: DateRangeListItem, index: number) => ({
              ...date,
              indexOfDateRange: index + 1,
            }));
          return {
            newSpaceIds: dailyVisitors.comparisonSpaceIds,
            newDateRanges,
          };
        } else {
          const newSpaceIds = dailyVisitors.comparisonSpaceIds.filter(
            (item: string) => item !== String(value.id)
          );
          return {
            newSpaceIds,
            newDateRanges: dailyVisitors.comparisonDateRanges,
          };
        }
      };
      const { newSpaceIds, newDateRanges } = deriveUpdatedState();
      updateComparisonParams(newSpaceIds, newDateRanges);
      const parsedByDateData = JSON.parse(visitorsData.byDate.chartData);
      const parsedByHourData = JSON.parse(visitorsData.byHour.chartData);
      const availableDates = deriveAvailableDates(dateRange, selectedDays);
      const isDateComparison = Boolean(newDateRanges.length);
      const { newOptions, dailyVisitorsSeriesMetaData, dataMax } =
        deriveDailyVisitorsChartOptions(
          parsedByDateData,
          isDateComparison,
          dailyVisitors.options,
          nodes,
          newSpaceIds,
          newDateRanges,
          dailyVisitors.chartMetaData,
          dateRange
        );

      const [
        dailyVisitorsRows,
        dailyVisitorsColumns,
        dailyVisitorsGroupingModel,
      ] = deriveDailyVisitorsTableSpacesComparisonData(
        dateRange,
        availableDates,
        newDateRanges,
        isDateComparison,
        newSpaceIds,
        parsedByDateData,
        nodes,
        isMobileSized
      );

      const { newHourOptions, hourlyVisitorsSeriesMetaData } =
        deriveHourlyVisitorsChartOptions(
          parsedByHourData,
          isDateComparison,
          hourlyVisitors.options,
          nodes,
          newSpaceIds,
          newDateRanges,
          hourlyVisitors.chartMetaData,
          dateRange
        );

      const [
        hourlyVisitorsRows,
        hourlyVisitorsColumns,
        hourlyVisitorsGroupingModel,
      ] = deriveHourlyVisitorsTableSpacesComparisonData(
        dateRange,
        availableDates,
        timeRange,
        newDateRanges,
        isDateComparison,
        newSpaceIds,
        parsedByHourData,
        nodes,
        isMobileSized
      );

      return {
        ...newState,
        tabs: {
          ...newState.tabs,
          visitors: {
            ...newState.tabs.visitors,
            daily: {
              columns: dailyVisitorsColumns,
              rows: dailyVisitorsRows,
              groupingModel: dailyVisitorsGroupingModel,
            },
            hourly: {
              columns: hourlyVisitorsColumns,
              rows: hourlyVisitorsRows,
              groupingModel: hourlyVisitorsGroupingModel,
            },
          },
        },
        dailyVisitors: {
          ...newState.dailyVisitors,
          options: newOptions,
          comparisonSpaceIds: newSpaceIds,
          comparisonDateRanges: newDateRanges,
          chartMetaData: dailyVisitorsSeriesMetaData,
          busiestTotal: dataMax,
        },
        hourlyVisitors: {
          ...newState.hourlyVisitors,
          options: newHourOptions,
          comparisonSpaceIds: newSpaceIds,
          comparisonDateRanges: newDateRanges,
          chartMetaData: hourlyVisitorsSeriesMetaData,
        },
      };
    }
    case ActionType.AddDateTimeComparison: {
      const newState = { ...state };
      const {
        dialogs: { addComparison },
      } = newState;
      const datesWithIndex: DateRangeListItem[] = value.map(
        (date: DateRangeListItem, index: number) => ({
          ...date,
          indexOfDateRange: index + 1,
        })
      );
      addComparison.comparisonDateRanges = datesWithIndex;
      addComparison.comparisonSpaceIds = [String(newState.spaceId)];
      return newState;
    }
    case ActionType.DateChartToggleYAxisPercent: {
      const newState = { ...state };
      const { checked, capacity, nodes, showEarlyPreview } = value;
      const {
        dailyOccupancyChart: {
          shouldDisplayAverage,
          shouldDisplayPeak,
          options,
          chartMetaData,
          isYAxisZoomed,
          comparisonSpaceIds,
          comparisonDateRanges,
        },
        pageLoad: { occupancyData },
        filters: { dateRange },
      } = newState;
      if (!occupancyData) return newState;
      const parsedData = JSON.parse(occupancyData.byDate.chartData);

      const { newOptions, daySeriesMetaData, dataMax } = deriveDateChartOptions(
        parsedData,
        false,
        shouldDisplayAverage,
        shouldDisplayPeak,
        isYAxisZoomed,
        options,
        checked,
        nodes,
        comparisonSpaceIds,
        comparisonDateRanges,
        chartMetaData,
        dateRange,
        capacity,
        showEarlyPreview
      );
      return {
        ...newState,
        dailyOccupancyChart: {
          ...newState.dailyOccupancyChart,
          chartMetaData: daySeriesMetaData,
          options: newOptions,
          isYAxisPercentage: checked,
          busiestTotal: dataMax,
        },
      };
    }
    case ActionType.HourChartToggleYAxisPercent: {
      const newState = { ...state };
      const { checked, capacity, nodes, showEarlyPreview } = value;
      const {
        hourlyOccupancyChart: {
          shouldDisplayAverage,
          shouldDisplayPeak,
          options,
          chartMetaData,
          isYAxisZoomed,
          comparisonSpaceIds,
          comparisonDateRanges,
        },
        filters: { dateRange },
        pageLoad: { occupancyData },
      } = newState;
      if (!occupancyData) return newState;
      const parsedData = JSON.parse(occupancyData.byHour.chartData);

      const { newHourOptions, hourSeriesMetaData, dataMax } =
        deriveHourChartOptions(
          parsedData,
          false,
          shouldDisplayAverage,
          shouldDisplayPeak,
          isYAxisZoomed,
          options,
          checked,
          nodes,
          comparisonSpaceIds,
          comparisonDateRanges,
          chartMetaData,
          dateRange,
          capacity,
          showEarlyPreview
        );

      return {
        ...newState,
        hourlyOccupancyChart: {
          ...newState.hourlyOccupancyChart,
          chartMetaData: hourSeriesMetaData,
          options: newHourOptions,
          isYAxisPercentage: checked,
          busiestTotal: dataMax,
        },
      };
    }
    case ActionType.DateChartToggleStacked: {
      const newState = { ...state };
      const {
        pageLoad: { occupancyData },
        filters: { dateRange },
        nodes,
        spaceId,
        dailyOccupancyChart: {
          shouldDisplayAverage,
          options,
          chartMetaData,
          isYAxisZoomed,
          comparisonSpaceIds,
          comparisonDateRanges,
          isYAxisPercentage,
        },
      } = newState;
      const { checked, showEarlyPreview } = value;
      // TODO: common plot options
      const plotOptions = checked
        ? {
            plotOptions: {
              column: {
                stacking: "normal",
              },
            },
          }
        : {
            plotOptions: {
              column: {
                stacking: false,
              },
            },
          };

      if (!occupancyData) return newState;
      const parsedData = JSON.parse(occupancyData.byDate.chartData);
      const capacity = nodes[spaceId as number].capacity;
      const isDateComparison = Boolean(comparisonDateRanges.length > 0);

      const { newOptions, daySeriesMetaData } = deriveDateChartOptions(
        parsedData,
        isDateComparison,
        shouldDisplayAverage,
        false,
        isYAxisZoomed,
        options,
        isYAxisPercentage,
        nodes,
        comparisonSpaceIds,
        comparisonDateRanges,
        chartMetaData,
        dateRange,
        capacity,
        showEarlyPreview
      );
      const optionsWithNewPlot = {
        ...newOptions,
        ...(plotOptions as AnyVal),
      };
      return {
        ...newState,
        dailyOccupancyChart: {
          ...newState.dailyOccupancyChart,
          options: optionsWithNewPlot,
          chartMetaData: daySeriesMetaData,
          toggleSeriesView: checked,
          // Turn off peak when stacked is turned on
          shouldDisplayPeak: false,
        },
      };
    }
    case ActionType.DailyDwellChartToggleStacked: {
      const newState = { ...state };
      const {
        pageLoad: { dwelltimeData },
        filters: { dateRange },
        nodes,
        dailyDwelltime: {
          shouldDisplayAverage,
          options,
          chartMetaData,
          comparisonSpaceIds,
          comparisonDateRanges,
        },
      } = newState;
      const { checked } = value;
      const plotOptions = checked
        ? {
            plotOptions: {
              column: {
                stacking: "normal",
              },
            },
          }
        : {
            plotOptions: {
              column: {
                stacking: false,
              },
            },
          };

      if (!dwelltimeData) return newState;
      const parsedData = JSON.parse(dwelltimeData.byDate.chartData);

      const isDateComparison = Boolean(comparisonDateRanges.length > 0);

      const { newOptions, dailyDwellSeriesMetaData } =
        deriveDailyDwellChartOptions(
          parsedData,
          isDateComparison,
          shouldDisplayAverage,
          false,
          options,
          nodes,
          comparisonSpaceIds,
          comparisonDateRanges,
          chartMetaData,
          dateRange
        );
      const optionsWithNewPlot = {
        ...newOptions,
        ...(plotOptions as AnyVal),
      };
      return {
        ...newState,
        dailyDwelltime: {
          ...newState.dailyDwelltime,
          options: optionsWithNewPlot,
          chartMetaData: dailyDwellSeriesMetaData,
          toggleSeriesView: checked,
          // Turn off peak when stacked is turned on
          shouldDisplayPeak: false,
        },
      };
    }
    case ActionType.DailyVisitorsChartToggleStacked: {
      const newState = { ...state };
      const {
        pageLoad: { visitorsData },
        filters: { dateRange },
        nodes,
        dailyVisitors: {
          options,
          chartMetaData,
          comparisonDateRanges,
          comparisonSpaceIds,
        },
      } = newState;
      const { checked } = value;
      // TODO: common plot options
      const plotOptions = checked
        ? {
            plotOptions: {
              column: {
                stacking: "normal",
              },
            },
          }
        : {
            plotOptions: {
              column: {
                stacking: false,
              },
            },
          };

      if (!visitorsData) return newState;
      const parsedData = JSON.parse(visitorsData.byDate.chartData);
      const isDateComparison = Boolean(comparisonDateRanges.length > 0);
      const { newOptions, dailyVisitorsSeriesMetaData } =
        deriveDailyVisitorsChartOptions(
          parsedData,
          isDateComparison,
          options,
          nodes,
          comparisonSpaceIds,
          comparisonDateRanges,
          chartMetaData,
          dateRange
        );
      const optionsWithNewPlot = {
        ...newOptions,
        ...(plotOptions as AnyVal),
      };
      return {
        ...newState,
        dailyVisitors: {
          ...newState.dailyVisitors,
          options: optionsWithNewPlot,
          chartMetaData: dailyVisitorsSeriesMetaData,
          toggleSeriesView: checked,
        },
      };
    }
    case ActionType.AddComparisonImportDateChartClick: {
      const newState = { ...state };
      const {
        dailyOccupancyChart: { comparisonDateRanges, comparisonSpaceIds },
        dialogs: { addComparison },
      } = newState;
      const newDateRanges: DateRangeListItem[] = comparisonDateRanges.map(
        (date: DateRangeListItem, index: number) => ({
          ...date,
          indexOfDateRange: index + 1,
        })
      );
      addComparison.comparisonSpaceIds = comparisonSpaceIds;
      addComparison.comparisonDateRanges = newDateRanges;
      return newState;
    }
    case ActionType.AddComparisonImportHourChartClick: {
      const newState = { ...state };
      const {
        hourlyOccupancyChart: { comparisonDateRanges, comparisonSpaceIds },
        dialogs: { addComparison },
      } = newState;
      const newDateRanges: DateRangeListItem[] = comparisonDateRanges.map(
        (date: DateRangeListItem, index: number) => ({
          ...date,
          indexOfDateRange: index + 1,
        })
      );
      addComparison.comparisonSpaceIds = comparisonSpaceIds;
      addComparison.comparisonDateRanges = newDateRanges;
      return newState;
    }
    default:
      throw new Error("analytics view state unexpected action");
  }
}
