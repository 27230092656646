import { Amplify, Hub } from "aws-amplify";
import { LicenseInfo } from "@mui/x-license-pro";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import ApolloAppSyncClient from "./ApolloAppsyncClient";
import { ApolloProvider } from "@apollo/client";
import theme from "./theme";
import AuthProvider from "./AuthProvider";
import View from "./View";
import ErrorBoundary from "./components/ErrorBoundary";
import LoggedInErrorBoundary from "./components/LoggedInErrorBoundary";
import OccuRouter from "./OccuRouter";
import { OccuUser } from "./interfaces";
import ViewProvider from "./ViewProvider";
import TreeProvider from "./TreeProvider";
import AuthContext from "./authContext";
import TrackerProvider from "./TrackerProvider";

LicenseInfo.setLicenseKey(
  "6c51664a97ccbf82e4133d8ddf0904e9Tz0xMDYyMjYsRT0xNzY4NzgwNzk5MDAwLFM9cHJvLExNPXBlcnBldHVhbCxQVj1RMy0yMDI0LEtWPTI="
);

Amplify.configure({
  Auth: {
    authenticationFlowType: "USER_PASSWORD_AUTH",
    region: "us-west-2",
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
  },
});

Hub.listen("auth", (data) => {
  switch (data.payload.event) {
    case "tokenRefresh_failure": {
      window.location.reload();
      break;
    }
  }
});

export default function App() {
  return (
    <TrackerProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <SnackbarProvider
              autoHideDuration={4000}
              maxSnack={6}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Router>
                <ErrorBoundary>
                  <ApolloProvider client={ApolloAppSyncClient}>
                    <AuthProvider>
                      <LoggedInErrorBoundary>
                        <TreeProvider>
                          <ViewProvider>
                            <View>
                              <AuthContext.Consumer>
                                {(authContext) => {
                                  return (
                                    <OccuRouter
                                      user={authContext.occuUser as OccuUser}
                                    />
                                  );
                                }}
                              </AuthContext.Consumer>
                            </View>
                          </ViewProvider>
                        </TreeProvider>
                      </LoggedInErrorBoundary>
                    </AuthProvider>
                  </ApolloProvider>
                </ErrorBoundary>
              </Router>
            </SnackbarProvider>
          </LocalizationProvider>
        </CssBaseline>
      </ThemeProvider>
    </TrackerProvider>
  );
}
