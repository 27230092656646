import React from "react";
import { AppRoutes } from "./routing";
import {
  defaultFilters as defaultUserManagementFilters,
  Filters as UserManagementFilters,
  ChangeRoleFn,
  ChangeStatusFn,
} from "./views/UserManagement/interfaces";
import {
  PageChangeHandler,
  ReactChangeEventHandler,
  emptyFn,
} from "./interfaces";

interface ViewContext {
  isMobileSized: boolean;
  isLeftPanelOpen: boolean;
  isLeftPanelRelevant: boolean;
  isRightPanelOpen: boolean;
  isRightPanelRelevant: boolean;
  isPortfolioToolRelevant: boolean;
  isPortfolioToolOpen: boolean;
  handlePortfolioToolToggle: (
    spaceId: number,
    route: AppRoutes,
    paramsString: string
  ) => void;
  handleLeftPanelToggle: () => void;
  handleRightPanelToggle: () => void;
  handleNavigationClick: (route: AppRoutes) => void;
  userManagement: {
    filters: UserManagementFilters;
    handleStatusFilterChange: ChangeStatusFn;
    handleRoleFilterChange: ChangeRoleFn;
    handleSearchChange: ReactChangeEventHandler;
    handlePageChange: PageChangeHandler;
  };
  spaceId: number | null;
}

const ViewContext = React.createContext<ViewContext>({
  isLeftPanelOpen: false,
  isLeftPanelRelevant: false,
  isRightPanelOpen: false,
  isRightPanelRelevant: false,
  isMobileSized: false,
  isPortfolioToolOpen: false,
  isPortfolioToolRelevant: false,
  handlePortfolioToolToggle: () => emptyFn,
  handleLeftPanelToggle: emptyFn,
  handleRightPanelToggle: emptyFn,
  handleNavigationClick: emptyFn,
  userManagement: {
    filters: { ...defaultUserManagementFilters },
    handleStatusFilterChange: () => emptyFn,
    handleRoleFilterChange: () => emptyFn,
    handleSearchChange: emptyFn,
    handlePageChange: emptyFn,
  },
  spaceId: null,
});

export default ViewContext;
