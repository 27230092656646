import React, { Dispatch } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import {
  BreakHoursTabState,
  HourBreakQueryResult,
  handleAddHourBreakClick,
} from "../../state/hours-dialog";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";
import {
  Action,
  handleBreakHourDateRangeChange,
  handleBreakHourNameChange,
} from "../../state/hours-dialog";
import HoursForm from "../HoursForm";

interface Props {
  dispatch: Dispatch<Action>;
  hourBreaks: HourBreakQueryResult[];
  breakHoursTab: BreakHoursTabState;
  currentHourBreakId: number | null;
  onGetHourBreakByIdClick: (hourBreakId: number) => () => void;
  isLoading: boolean;
}

const BreakHoursContent: React.FC<Props> = ({
  dispatch,
  hourBreaks,
  breakHoursTab: { breakName, newWeekdaysWithHours, dateRange, isAddMode },
  currentHourBreakId,
  onGetHourBreakByIdClick,
  isLoading,
}) => {
  if (!isAddMode && (!hourBreaks || hourBreaks?.length === 0)) {
    return (
      <Box
        height={450}
        flex={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DateRangeIcon
          sx={{ color: "grey.300", fontSize: 200 }}
          fontSize="large"
        />
        <Typography
          variant="h3"
          align="center"
          fontWeight={600}
          color="grey.500"
          gutterBottom
        >
          No multiple-day special hours exist yet
        </Typography>
        <Typography variant="h5" align="center" color="grey.400">
          <Link
            underline="none"
            color="primary"
            sx={{ cursor: "pointer", fontSize: "inherit" }}
            onClick={handleAddHourBreakClick(dispatch)}
          >
            Create a new special hours entry
          </Link>{" "}
          for a specific date range
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" gap={4}>
      {!isAddMode && (
        <Box flex={1} borderRight={1} borderColor="grey.200" pr={4}>
          <Divider />
          <Box
            sx={{
              overflowY: "auto",
              height: 450,
            }}
          >
            {hourBreaks &&
              hourBreaks.length > 0 &&
              hourBreaks.map((hourBreak, index) => {
                const isSelected = hourBreak.id === currentHourBreakId;
                const start = DateTime.fromISO(hourBreak.startDate, {
                  setZone: true,
                }).toLocaleString(DateTime.DATE_MED);
                const end = DateTime.fromISO(hourBreak.endDate, {
                  setZone: true,
                }).toLocaleString(DateTime.DATE_MED);
                const subTitle = hourBreak.name || "-";
                return (
                  <Box
                    key={index}
                    p={2}
                    borderBottom={1}
                    borderColor="grey.200"
                    sx={{
                      "&:hover": {
                        backgroundColor: "grey.200",
                        cursor: "pointer",
                      },
                      backgroundColor: isSelected ? "grey.100" : "transparent",
                    }}
                    onClick={onGetHourBreakByIdClick(hourBreak.id)}
                  >
                    <Box>
                      <Typography variant="body2" fontWeight={600}>
                        {start} - {end}
                      </Typography>
                      <Typography variant="body2" color="grey.600">
                        {subTitle}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}
      {currentHourBreakId || isAddMode ? (
        <Box flex={4} sx={{ opacity: isLoading ? 0.5 : 1 }}>
          <DateRangePicker
            disabled={isLoading}
            value={dateRange}
            onChange={handleBreakHourDateRangeChange(dispatch)}
            renderInput={(startProps, endProps) => (
              <Box display="flex" gap={4} alignItems="center" mt={4} mb={2}>
                <TextField
                  {...startProps}
                  label="Start Date"
                  helperText={null}
                  disabled={isLoading}
                />
                <Typography variant="body2" color="grey.500">
                  TO
                </Typography>
                <TextField
                  {...endProps}
                  label="End Date"
                  helperText={null}
                  disabled={isLoading}
                />
              </Box>
            )}
          />
          <TextField
            disabled={isLoading}
            value={breakName}
            onChange={handleBreakHourNameChange(dispatch)}
            placeholder="Name your special hours"
            fullWidth
            size="small"
            autoComplete="rule-name"
            label="Special Hours Name"
            sx={{
              pb: 3,
              mt: 6,
            }}
          />
          <HoursForm
            newWeekdaysWithHours={newWeekdaysWithHours}
            dispatch={dispatch}
            sliderMaxWidth={isAddMode ? 660 : 440}
            isLoading={isLoading}
            tabName="breakHoursTab"
            dateRange={dateRange}
          />
        </Box>
      ) : (
        <Box
          flex={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DateRangeIcon
              sx={{ color: "grey.300", fontSize: 200 }}
              fontSize="large"
            />
            <Typography
              variant="h3"
              align="center"
              fontWeight={600}
              color="grey.500"
              gutterBottom
            >
              No multiple-day special hours selected
            </Typography>
            <Typography variant="h5" align="center" color="grey.400">
              Select a special hours entry for a specific date range
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default BreakHoursContent;
