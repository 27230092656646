import { DateTime } from "luxon";

export interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}
export interface FiltersState {
  dateRange: DateRange;
  timeRange: number[];
  selectedDays: number[];
  selectedSpaceIds: string[];
  hasChangedHours: boolean;
  hasChangedDates: boolean;
  hasChangedDays: boolean;
  omitZero: boolean;
}

export const defaultFilters = {
  dateRange: {
    startDate: DateTime.now().minus({ days: 8 }).toJSDate(),
    endDate: DateTime.now().minus({ days: 1 }).toJSDate(),
    key: "selection",
  },
  selectedDays: [1, 2, 3, 4, 5, 6, 7],
  selectedSpaceIds: [],
  hasChangedHours: false,
  hasChangedDates: false,
  hasChangedDays: false,
  omitZero: false,
};

export function getTimeRangeChangeState(state: FiltersState, value: number[]) {
  return {
    ...state,
    timeRange: value as number[],
  };
}
