import React, { useContext } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import BusinessIcon from "@mui/icons-material/Business";
import ViewContext from "../../ViewContext";
import { AppRoutes } from "../../routing";
import TreeContext from "../../TreeContext";

type Props = Record<string, never>;

const AnalyticsNavIcons: React.FC<Props> = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const paramsString = searchParams.toString();
  const isPortfolioToolOpen = pathname.includes(AppRoutes.Portfolio);
  const { trees } = useContext(TreeContext);
  const { handlePortfolioToolToggle, spaceId } = useContext(ViewContext);
  const spaceIdToUse = spaceId ? spaceId : trees[0]?.id ? trees[0].id : null;

  if (!spaceIdToUse) {
    return null;
  }

  const handleAnalyticsClick = () => {
    return handlePortfolioToolToggle(
      spaceIdToUse,
      AppRoutes.Analytics,
      paramsString
    );
  };

  const handlePortfolioClick = () => {
    return handlePortfolioToolToggle(
      spaceIdToUse,
      AppRoutes.Portfolio,
      paramsString
    );
  };
  return (
    <Box borderRight={1} borderColor="grey.200" px={2} pt={2} bgcolor="white">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={4}
      >
        <Tooltip
          title="Detailed analysis of each of your spaces"
          placement="right"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <IconButton
              onClick={handleAnalyticsClick}
              aria-label="analytics tool"
              sx={{
                p: "6px",
                borderRadius: 3,
                bgcolor: isPortfolioToolOpen ? "transparent" : "grey.200",
              }}
            >
              <BusinessIcon
                sx={{
                  clipPath: "inset(0 50% 0 0)", // Clip the right half
                  position: "relative",
                  right: "-22%", // Move the icon 25% to the left, effectively centering it
                  overflow: "hidden", // Clip the right half of the icon
                }}
              />
            </IconButton>
            <Typography
              variant="caption"
              textAlign="center"
              fontWeight={500}
              fontSize="0.70rem"
            >
              Space
            </Typography>
          </Box>
        </Tooltip>
        <Tooltip
          title="Portfolio wide analysis of all your spaces"
          placement="right"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <IconButton
              onClick={handlePortfolioClick}
              aria-label="portfolio tool"
              sx={{
                p: "6px",
                borderRadius: 3,
                bgcolor: isPortfolioToolOpen ? "grey.200" : "transparent",
              }}
            >
              <BusinessIcon />
            </IconButton>
            <Typography
              variant="caption"
              textAlign="center"
              fontWeight={500}
              fontSize="0.70rem"
            >
              Portfolio
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default AnalyticsNavIcons;
